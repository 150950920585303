import { Title } from '@/components/ui/Title';
import { Card, CardTitle, CardContent } from '@/components/ui/card';
import { useGetEvaluationprogress } from '../../../../openapi/api/endpoints/default/default';
import { GetEvaluationprogress200OngoingEvalProgressionItem } from 'openapi/api/model';
import { useContext } from 'react';
import { AuthContext } from '@/store/track-context';
import { TimelineNavigator } from './TimelineNavigator';


const Timeline=()=>{
    const {track}=useContext(AuthContext)
    const {data:trackProgress} = useGetEvaluationprogress({trackId:track?._id??''})
    
    return (
    <>
        <Card className="flex flex-col basis-1/2 m-4 p-4 bg-white border-none">
            <CardTitle className="flex justify-center items-center text-white">
                <Title>Your Evaluations</Title>
            </CardTitle>
            <CardContent className="h-[290px] flex-auto flex-col px-2 py-4 bg-white overflow-auto">
                {trackProgress ?     
                    trackProgress?.ongoingEvalProgression?.map((projectFromTrack: GetEvaluationprogress200OngoingEvalProgressionItem)=>
                        <TimelineNavigator projectFromTrack={projectFromTrack}/>
                    ) :
                    <div className='flex flex-col items-center justify-center text-center text-xl h-full'>No ongoing evaluations</div>
                } 
            </CardContent>
        </Card>
    </>
  );
}

export default Timeline;