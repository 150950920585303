import { Route } from "@tanstack/react-router";
import { MainAppRoute } from "@/rootRoutes/MainApp";
import HiringCampaignInput from "./HiringCampaignInput";
import { CardTitle } from "@/components/ui/card";
import HiringCampaignList from "./HiringCampaignList";
import NoAddTrackHeader from "@/components/Header/NoAddTrackHeader";

const Home = () => {
  return (
    <div className="flex flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
      <NoAddTrackHeader title="Home" />
      <div style={{ height: "calc(100dvh - 100px)" }}>
        <div className="flex flex-row space-x-4 justify-center items-center h-full  p-8 max-md:w-screen ">
          <div className="flex flex-grow flex-col space-x-4  ">
            <h1 className="flex justify-center font-bold text-3xl break-words truncate">
              Add New Evaluation
            </h1>

            <div className="flex flex-col basis-1/2 m-4 p-4 border-none">
              <div className="h-[1.75rem] "></div>
              <div className="h-[290px] flex flex-col item-center justify-center px-2 py-4 overflow-auto p-2">
                <CardTitle className="flex justify-center items-center text-white mb-4">
                  <h1 className="text-black text-bold text-xl break-words truncate">
                    Enter the evaluation code:
                  </h1>
                </CardTitle>
                <HiringCampaignInput />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center ">
            <div className="border-l-4 rounded-lg border-black h-96"></div>
          </div>

          <div className="flex-grow flex-col space-x-4 ">
            <h1 className="flex justify-center font-bold text-3xl break-words truncate">
              Start Evaluation
            </h1>

            <div>
              <HiringCampaignList/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomeRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "/home",
  component: Home,
});
export default HomeRoute;
