import * as React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 400,
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export const BasicModal=({children,open,handleClose}:{children:React.ReactNode,open:boolean,handleClose:()=>void})=> {
  

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div className="flex flex-col h-full justify-center items-center bg-white" >          
                        {children}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
