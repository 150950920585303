import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { PostApplyBody } from "openapi/api/model";

export const usePostApplication=({onSuccess, onError}:{onSuccess:()=>void, onError?:(error:AxiosError)=>void})=>{
   
    return useMutation({
        mutationFn:postApplication,
        onSuccess: ()=>{
            onSuccess&&onSuccess();
        },
        onError: (error)=>{
            onError&&onError(error as AxiosError)
        }
    })
}

const postApplication = async({formData}:{formData:PostApplyBody}) => {
    try{
        const response = await axios.post('https://api.apisdevf.net/apply/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    }catch(error){
        throw new Error(`${(error as AxiosError).message}`);
    }
}