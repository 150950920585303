import { Loader2 } from "lucide-react"

export default function LoadingComponent({
  isSubmitting,
  loadingMessage,
  loadingTitle,
}
  :{
    isSubmitting:boolean,
    loadingMessage:string,
    loadingTitle:string
  }) {

  return (
    <div className="p-4">
      {isSubmitting && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" aria-modal="true" role="dialog">
          <div className="bg-white rounded-lg p-8 max-w-sm w-full mx-4 text-center shadow-lg">
            <Loader2 className="animate-spin h-16 w-16 text-primary mx-auto mb-6" aria-hidden="true" />
            <h2 className="text-2xl font-semibold mb-4">{loadingTitle}</h2>
            <p className="text-muted-foreground">
              {loadingMessage}
            </p>
          </div>
        </div>
      )}

    </div>
  )
}