import * as z from "zod"

export const profileFormSchema = z.object({
    userName: z
      .string()
      .min(2, {
        message: "Username must be at least 2 characters.",
      })
      .max(30, {
        message: "Username must not be longer than 30 characters.",
      }),
    email: z
      .string({
        required_error: "Please select an email to display.",
      })
      .email(),
    bio: z.string().max(2000).min(0),
    github_ID: z
      .string()
      .min(2, {
        message: "GithubId must be at least 2 characters.",
      })
      .max(30, {
        message: "GithubId must not be longer than 30 characters.",
      })
  })

export type ProfileFormValues = z.infer<typeof profileFormSchema>