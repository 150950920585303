
import { createContext, useState } from 'react';
import { WorkSpaceConfigStates } from '../utils/Websocket/WorkspaceTypes';


export const WorkspaceContext = createContext({
    workspaceStatus: undefined as WorkSpaceConfigStates|undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setStatus: (_workspaceStatus:WorkSpaceConfigStates|undefined)=>{},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WorkspaceContextProvider = ({children}: any) => {
    const [podStatus, setPodStatus] = useState<WorkSpaceConfigStates>();
    const setStatus = (workspaceStatus:WorkSpaceConfigStates|undefined) => {
        setPodStatus(workspaceStatus);
    }


    const value = {
        workspaceStatus: podStatus,
        setStatus: setStatus,
    }

    return(
        <WorkspaceContext.Provider value={value}>
            {children}
        </WorkspaceContext.Provider>
    )
}

export default WorkspaceContextProvider;