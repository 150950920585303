import { Outlet, Router, rootRouteWithContext } from '@tanstack/react-router'
import OverViewRoute from './pages/Overview/OverviewPage'
import AboutRoute from './pages/About/AboutPage'
import './index.css'
import '../app/globals.css'
import { QueryClient } from '@tanstack/react-query';
import StartEvaluationRoute from './pages/Project/StartEvaluationPage'
import AuthRoute from './pages/Authentication/AuthenticationPage'
import { MainAppRoute } from './rootRoutes/MainApp'
import ProfileRoute from './pages/Profile/ProfilePage'
import AuthContextProvider from './store/track-context';
import WorkspaceContextProvider from './store/workspace-context'
import BeginTestRoute from './pages/Project/BeginTestPage'
import ProjectResultsRoute from './pages/Project/ProjectResultsPage'
import HomeRoute from './pages/Home/HomePage'
import DownloadSEBRoute from './pages/Project/DownloadSEBPage'
import ApplicationRoute from './pages/CandidateApplication/ApplicationPage'
import ApplicationSubmittedRoute from './pages/CandidateApplication/ApplicationSubmitted'
import ResumeSignUpPageRoute from './pages/Authentication/ResumeSignUpPage'
import NotFoundComponent from './pages/NotFound/NotFoundComponent'
import PasswordResetPageRoute from './pages/Authentication/PasswordResetPage'
//import ChatEvaluationRoute from './pages/Project/Chat/ChatEvaluation'
import BeginAlgoTestRoute from './pages/Project/BeginAlgoTestPage'
import BeginQATestRoute from './pages/Project/BeginQATestPage'
import TestRoute from './pages/Project/TestPage'
//import EvaluationRoute from './pages/LiveEvaluations/Workspace/WorkspaceComponent'
//import EvaluationRoute from './pages/LiveEvaluations/Camera/EvalPage'

export const queryClient=new QueryClient()
queryClient.setMutationDefaults(['Auth'],{
  gcTime:60*60*1000 //1h before cache is garbage collected
});
queryClient.setQueryDefaults(['Auth'],{
  gcTime:60*60*1000 //1h before cache is garbage collected
})


export const rootRoute = rootRouteWithContext<{
  queryClient: QueryClient
  
}>()({
  component: ()=> 
    <AuthContextProvider>
      <WorkspaceContextProvider>
        <Outlet />
      </WorkspaceContextProvider>
    </AuthContextProvider>,
  notFoundComponent: NotFoundComponent

})

const routeTree = rootRoute.addChildren([
  AuthRoute,
  ApplicationRoute,
  ApplicationSubmittedRoute,
  ResumeSignUpPageRoute,
  PasswordResetPageRoute,
  MainAppRoute.addChildren([
    HomeRoute,
    OverViewRoute, 
    AboutRoute, 
    //EvaluationRoute,
    StartEvaluationRoute, 
    ProfileRoute, 
    ProjectResultsRoute,
    BeginTestRoute,
    BeginAlgoTestRoute,
    BeginQATestRoute,
    DownloadSEBRoute,
    TestRoute
    //ChatEvaluationRoute
  ]), //add start project route

  
])
export const router = new Router({ 
  routeTree,
  context: {
    queryClient: queryClient
  },

})