import { useEffect, useState } from "react";
import {WorkspaceSocket} from '@/utils/Websocket/socket'
import { WorkSpaceConfigStates, CodeServerArgs, Skill } from "./WorkspaceTypes";



const useWorkspace= ({onSuccess}:{onSuccess?:()=>void})=>{
  const [workspaceState, setWorkspaceState] = useState<WorkSpaceConfigStates>();
  const [workspaceDetails,setWorkspaceDetails]=useState<{podName?:string,url?:string,workspaceArgs?:CodeServerArgs}>()


  const {userName,projectId}={
    projectId:workspaceDetails?.workspaceArgs?.projectId,
    userName:workspaceDetails?.workspaceArgs?.userName,
  }


//-------------------------------------------------------------------------------------------//

  const deleteWorkspace=()=>{
    const username=userName?.toLowerCase()
    console.log('Deleting workspace...')
    setWorkspaceState({status:'podDeleted'})
    WorkspaceSocket.emit("deleteWorkspace",{userName:username,projectId})
  }

  const createWorkSpace=({userName,projectId,repoName,skill}:CodeServerArgs)=>{
    const username=userName.toLowerCase()
    if (WorkspaceSocket.connected) {
      WorkspaceSocket.disconnect();
    }
    WorkspaceSocket.io.opts.query = {userName:username,projectId,repoName,skill};
    WorkspaceSocket.connect();
    setWorkspaceDetails(workSpaceDetails=>({...workSpaceDetails,workspaceArgs:{userName,projectId}}))
    console.log('creating workspace...',{userName,projectId})
    WorkspaceSocket.emit("StartEvaluation")
    WorkspaceSocket.emit("getCurrentProjectTemplateId")
  }


  useEffect(()=>{
    //WorkspaceSocket.emit("getCurrentProjectTemplateId")
    const getProjectTemplateIdAndSkill=({templateId,skill}:{templateId:string,skill:Skill})=>{
      console.log("TempId: ",{templateId,skill})
      setWorkspaceDetails(prevDetails=>({...prevDetails, workspaceArgs:{...prevDetails?.workspaceArgs, templateId: templateId??'', skill: skill??''} as CodeServerArgs}))
    }
  
    WorkspaceSocket.on("templateId",getProjectTemplateIdAndSkill)
    return () => {
      WorkspaceSocket.off('templateId');
    };
  }, [workspaceDetails?.workspaceArgs])

//-------------------------------------------------------------------------------------------//
  useEffect(() => {
    const workspaceCheckRoutineInit=(url:string)=>{
      
      const {userName,projectId}=workspaceDetails?.workspaceArgs as CodeServerArgs
      setWorkspaceState({status:'workspaceLoading'})
      setWorkspaceDetails(prevPodName=>({...prevPodName,url}))
      WorkspaceSocket.emit('podCreated?',{projectId,userName})
    }

    WorkspaceSocket.on('podInitialized', workspaceCheckRoutineInit);

    return () => {
      WorkspaceSocket.off('podInitialized');
    };
  }, [workspaceDetails?.workspaceArgs]);



  useEffect(() => {
    const podCreationCheck=({podName}:{podName:string | false} )=>{
      if(!podName){
        setTimeout(()=>{
          WorkspaceSocket.emit('podCreated?',{userName,projectId})
        },1000)
        return
      }
      WorkspaceSocket.emit('podReady?',podName)
      setWorkspaceDetails(prevWorkspaceState=>({...prevWorkspaceState,podName}))
    }

    WorkspaceSocket.on('podCreated?', podCreationCheck);

    return () => {
      WorkspaceSocket.off('podCreated?');
    };
  }, [userName,projectId]);

//-------------------------------------------------------------------------------------------//

  useEffect(() => {
    const podReadinessCheck=(isPodCreated: string | false | undefined)=>{
      if(!isPodCreated){
        console.log({isPodCreated})
        setTimeout(()=>{
          WorkspaceSocket.emit('podReady?',workspaceDetails?.podName)
        },10000)
        return 
      }
      setTimeout(()=>{
        setWorkspaceState({status:'podReady'})
        onSuccess&&onSuccess()
      },15000)
      

    }

    WorkspaceSocket.on('podReady?', podReadinessCheck);

    return () => {
      WorkspaceSocket.off('podReady?');
    };
  }, [workspaceDetails]);

//-------------------------------------------------------------------------------------------//

  useEffect(() => {
    const onWorkspaceState=(error:unknown)=>{
      console.log({worspaceCreateError:error})
      setWorkspaceState({status:'error'})
      throw error
    }

    WorkspaceSocket.on('error', onWorkspaceState);

    return () => {
      WorkspaceSocket.off('error');
    };
  }, []);




  return {createWorkSpace,workspaceState,deleteWorkspace,workspaceDetails}
}

export default useWorkspace