import { Route } from '@tanstack/react-router';
import { MainAppRoute } from "@/rootRoutes/MainApp"
import { Card, CardContent } from '@/components/ui/card';
import { Toaster } from "sonner"
import { ProfileForm } from './components/ProfileForm';
import NoAddTrackHeader from '@/components/Header/NoAddTrackHeader';


const ProfilePage=()=>{

  return (
    <>
      <div className="flex flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
        <NoAddTrackHeader title='Profile'/>
        <div className="flex flex-col m-4">
            <Card className="border-none bg-white">
                <CardContent className="p-4">
                    <ProfileForm/>
                </CardContent>
            </Card>
        </div>
    </div>
    <Toaster/>
    </>

  )
}

const ProfileRoute = new Route({
    getParentRoute: () => MainAppRoute,
    path: "/profile",
    component: ProfilePage,
})

export default ProfileRoute;