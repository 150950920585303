
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MainAppRoute } from "@/rootRoutes/MainApp";
import { Route, useNavigate, useParams } from '@tanstack/react-router';
import { useGetProjectsProjectId } from '../../../openapi/api/endpoints/default/default';
import WorkspaceComponent from "@/components/Workspace/WorkspaceComponent";
import { useContext, useEffect } from "react";
import { AuthContext } from "@/store/track-context";
import { Button } from "@/components/ui/button";
import StartAlgoText from "./CreateProject/components/StartEvalTexts/StartAlgoText";
import StartProjectText from "./CreateProject/components/StartEvalTexts/StartProjectText";
import StartQAText from "./CreateProject/components/StartEvalTexts/StartQAText";
import TestDoneText from "./CreateProject/components/StartEvalTexts/TestDoneText";
import { AlgoBasedSkills, ProjectBasedSkills } from "@/utils/Types/skills";
//import HeaderComponent from "@/components/Header/Header";


const StartEvaluation = () => {
  const {courseId,skill,type} = useParams({from:'/'});
  const {isLoading} = useGetProjectsProjectId(courseId)
  const {track} = useContext(AuthContext)
  const navigate = useNavigate({from:'/'})
  const currentProject = track?.trackProgression.find((trackProgressionItem)=>trackProgressionItem.projectID===courseId)
  
  let content
  let evalType: string
    

  useEffect(()=>{
    if(!track){
      navigate({to:'/home'})
    }
  },[track, navigate])
  
  if(isLoading){
    return(
      <div className="flex flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
        ...Loading
      </div>
    )
  }

  const onStartEval = () => {
    navigate({
      to:'/course/$courseId/$skill/$type/$templateId',
      params:{
          courseId:courseId,
          skill:currentProject?.skill??'',
          type:evalType,
          templateId:currentProject?.templateId??'na'
        }
    })
  }

  switch (true) {
    case ((type=='project')&&(ProjectBasedSkills.includes(skill))):
      evalType = 'project'
      content = <div className="flex flex-col h-full items-center justify-center space-y-8">
        <StartProjectText/>
        <WorkspaceComponent projectId={courseId}/>
      </div>
      break;
    case ((type=='project')&&(AlgoBasedSkills.includes(skill))):
      evalType = 'algo'
      content = <div className="flex flex-col h-full items-center justify-center space-y-8">
        <StartAlgoText/>
        <Button onClick={onStartEval} className="rounded-full bg-teal100 hover:bg-blue100">Start</Button>
      </div>
      break;
    case type=='Q&A':
      evalType = 'QA'
      content = <div className="flex flex-col h-full items-center justify-center space-y-8">
        <StartQAText/>
        <Button onClick={onStartEval} className="rounded-full bg-teal100 hover:bg-blue100">Start</Button>
      </div>
        break;
    default:
      content = <TestDoneText/>
      break;
}
  
  
  return (
    <div className="flex flex-col justify-center items-center p-4 bg-gray-100 min-h-screen space-y-8 max-md:w-screen overflow-x-auto">
      
      <div className="flex flex-col h-full justify-center items-center space-y-8">

        {content}

      </div>
    </div>
    
  )
}

const StartEvaluationRoute = new Route({
    getParentRoute: () => MainAppRoute,
    path: "course/$courseId/$skill/$type",
    component: StartEvaluation,
    stringifyParams: ({ courseId, skill, type }) => ({ courseId: `${courseId}`, skill: `${skill}`, type: `${type}` }),
})

export default StartEvaluationRoute;
