
export const convertExpectedResultToString=(expectedResult: any):string=>{
    if (Array.isArray(expectedResult)) {
        // Handle array of results
        return expectedResult.map(item => JSON.stringify(item, null, 2)).join('\n');
    } else if (typeof expectedResult === 'object') {
        // Handle single object result
        return JSON.stringify(expectedResult, null, 2);
    } else {
        // Handle simple message or primitive result
        return String(expectedResult);
    }
}

export const extractNumbers=(inputString: string):string=>{
    // Regular expression to find all sequences of digits
    const regex = /\d+/g;
    // Using match() with regex to find all matches
    const matches = inputString.match(regex);
    // Joining the array of matches into a single string
    const result = matches ? matches.join('') : '';
    return result;
}

export const extractLetters=(inputString: string):string=>{
    // Regular expression to match alphabetic characters (letters)
    const regex = /[a-zA-Z]+/g;
    // Using match() with regex to find all matches
    const matches = inputString.match(regex);
    // Joining the array of matches into a single string
    const result = matches ? matches.join('') : '';
    return result;
}

export const capitalizeFirstLetter=(str:string)=>{
    if (!str) return str; // Return the original string if it's empty
    return str.charAt(0).toUpperCase() + str.slice(1);
}
