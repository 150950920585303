import { Input } from "@/components/ui/input";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { Form, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { usePostTracks } from "../../../openapi/api/endpoints/default/default";
import { PostTracksMutationBody } from "../../../openapi/api/endpoints/default/default";
import { toast } from "sonner";
import { queryClient } from "@/App";
import { CustomAxiosError } from "../Authentication/AuthComponents/ResumeSignUp";

const CodeInputSchema = z.object({
  code: z.string().min(2, {
    message: "Please enter a correct code.",
  }),
});
type CodeInputSchemaValue = z.infer<typeof CodeInputSchema>;

const HiringCampaignInput = () => {
  const form = useForm<CodeInputSchemaValue>({
    resolver: zodResolver(CodeInputSchema),
  });

  const { mutate } = usePostTracks({
    mutation: {
      onSuccess: () => {
        form.setValue('code','')
        toast.success("Submitted!", {
          description: "Hiring Campaign added successfully",
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: "#a3e635",
          },
        });
      },
      onSettled: ()=>{
        queryClient.invalidateQueries({queryKey: [`/tracks`]})
        queryClient.invalidateQueries({queryKey: [`/tracks/search`]})
      },
      onError:(error)=>{
        toast.error("Failed to add Hiring Campaign!",{
            description: `${(error as CustomAxiosError)?.response?.data.error}`,
            action: {
              label: "Dismiss",
              onClick: () => {},
            },
            style: {
              background: '#dc2626'
            }
          })
        throw({postTrackerr:error})
      }
    }
  })

  const onSubmit = (data: CodeInputSchemaValue) => {
    //const submittedCode=data as CodeInputSchemaValue
    const trackBody: PostTracksMutationBody = {
      hiringCampaignId: data.code,
    };
    mutate({ data: trackBody });
  };

  return (
    <div className="  ">
      <Form {...form}>
        <form className="" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="code"
            render={() => (
              <FormItem>
                <div className="grid grid-rows-2 items-center">
                  {/*<Label className="justify-self-center" htmlFor={"code"}>
                      Enter the job application code:
                    </Label>*/}
                  <div className="justify-center text-center max-w-0.5">
                    <Input
                      id="code"
                      {...form.register("code")}
                      name="code"
                      className="bg-white max-w-0.5"
                    />
                    <FormMessage />
                    <p className="text-slate-700">
                      This 24 digit code is provided by your hiring manager
                    </p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <Button className="bg-teal100 hover:bg-blue100" asChild>
                    <input type="submit" value={"Submit"} />
                  </Button>
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  );
};

export default HiringCampaignInput;
