
const StartAlgoText = () => {
    //Q&A or Algo
    return (
        <>
            <h1 className="text-2xl font-bold">Are you ready to start?</h1>
            <div className="flex flex-col items-center">
                <p>1.</p>
                <p className="flex flex-row">Once you click "Start" your evaluation has<p className="font-bold">&nbsp;begun&nbsp;</p> </p>
            </div>
            <div className="flex flex-col items-center">
                <p>2.</p>
                <div>
                    <p className="flex flex-row">You will have<p className="font-bold">&nbsp;45 minutes&nbsp;</p>to complete</p>
                </div>
            </div>
            <div className="flex flex-col items-center">
                <p>3.</p>
                <div className="flex flex-col items-center">  
                    <p className="flex flex-row items-center">If you<p className="font-bold">&nbsp;close the evaluation&nbsp;</p>at any time before completing it</p> 
                    <p className="flex flex-row">your result will appear as failed and you<p className="font-bold">&nbsp;cannot restart&nbsp;</p></p>
                </div>
            </div>
        </>
    )
}
  
export default StartAlgoText