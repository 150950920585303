
import { Header } from "@/components/ui/Title";

import { BellIcon, EnvelopeClosedIcon } from "@radix-ui/react-icons";
import AvatarComponent from "./Avatar";

const NoAddTrackHeader = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-row justify-between items-end mb-4">
      <div className="flex-grow text-left px-4 w-1/6 ">
        <Header>{title}</Header>
      </div>

      <div className="flex justify-center space-x-4 items-center px-4">
        <BellIcon width="22" height="26" color="gray" className="mx-2" />{" "}
        {/* mx-2 for some horizontal margin */}
        <EnvelopeClosedIcon
          width="23"
          height="26"
          color="gray"
          className="mx-2"
        />
        <AvatarComponent />
      </div>
    </div>
  );
};

export default NoAddTrackHeader;