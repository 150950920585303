import { CardTitle, CardDescription, Card, CardHeader, CardContent } from "@/components/ui/card";
//import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Title } from "@/components/ui/Title";

export type MacroProps={
  children:React.ReactNode,
  title?:string,
  description?:string,
  cardStyle?:string,
  cardHeaderStyle?:string,
  cardTitleStyle?:string,
  cardDescriptionStyle?:string,
  cardContentStyle?:string
}

const MacroCard = ({
  children,
  title,
  description,
  cardStyle,
  cardHeaderStyle,
  cardTitleStyle,
  cardDescriptionStyle,
  cardContentStyle
}:MacroProps) => {
  //const [buttonText,setButtonText] = useState('More Details')
 
    return (          
      <Card className={`flex flex-col m-4 p-4 bg-white border ${cardStyle}`}>
        <CardHeader className={`flex flex-col h-1/4 p-2 ${cardHeaderStyle}`}>
          <CardTitle className={`flex h-full text-white ${cardTitleStyle}`}>  
            <Title>{title}</Title>  
          </CardTitle>
          <CardDescription className={`${cardDescriptionStyle} flex h-full`}>
            <p className="truncate">{description}</p>
          </CardDescription>
        </CardHeader>
        <CardContent className={`grid grid-cols-1 justify-center items-center justify-items-center h-full ${cardContentStyle}`}>
          {children}
        </CardContent>
      </Card>
    ) 
}

export default MacroCard;
