import { rootRoute } from '@/App'
import { PasswordReset } from './AuthComponents/PasswordReset'
import { Route } from '@tanstack/react-router'
import { Toaster } from '@/components/ui/sonner'

export const PasswordResetPage = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-r from-blue-900 to-cyan-300">
        <PasswordReset/>
        <Toaster/>
    </div>
  )
}

const PasswordResetPageRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/passwordReset",
    component: PasswordResetPage,
})
export default PasswordResetPageRoute