//import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { PlusCircledIcon } from "@radix-ui/react-icons"
import * as z from "zod"
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from "@/components/ui/button"
import { useForm } from "react-hook-form"
import { Form, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { usePostTracks } from "../../../openapi/api/endpoints/default/default"
import { PostTracksMutationBody } from "../../../openapi/api/endpoints/default/default"
import { toast } from "sonner"
import { queryClient } from "@/App"


const CodeInputSchema = z.object({
  code: z
    .string()
    .min(2, {
      message: "Please enter a correct code.",
    })
    // .regex(new RegExp('\b[1-9]{8}\b'), {
    //   message: "Code must be 8 digits.",
    // })
    /* .min(8, {
      message: "Code must be 8 digits.",
    })
    .max(8, {
      message: "Code must be 8 digits.",
    }), */
  
})
type CodeInputSchemaValue = z.infer<typeof CodeInputSchema>

export function AddCampaign() {

  const form = useForm<CodeInputSchemaValue>({
    /* defaultValues: {
      code: "",
    } */
    resolver: zodResolver(CodeInputSchema)
    }
  )

  const {mutate} = usePostTracks({
    mutation: {
      onSuccess:()=>{
        form.setValue('code','')
        toast.success("Submitted!",{
          description: 'Hiring Campaign added successfully',
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: '#a3e635'
          }
        })
      },
      onSettled: ()=>{
        queryClient.invalidateQueries({queryKey: [`/tracks`]})
        queryClient.invalidateQueries({queryKey: [`/tracks/search`]})
      },
      onError:(err)=>{
        toast.error("Failed to add Hiring Campaign!",{
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: '#dc2626'
          }
        })
        throw({createTrackErr:err})
      }
    }
  })

  const onSubmit=(data:CodeInputSchemaValue)=>{
    //const submittedCode=data as CodeInputSchemaValue
    const trackBody:PostTracksMutationBody = {
      hiringCampaignId: data.code
    }
    mutate({data:trackBody})
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <PlusCircledIcon width="25" height="25"/>
      </PopoverTrigger>
      <PopoverContent className="w-80 bg-white">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="text-center font-bold leading-none">Add New Evaluation</h4>
          </div>
          <div className="grid gap-2">
          <Form {...form}>
          <form className="" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
          control={form.control}
          name="code"
          render={() => (
            <FormItem>
            <div className="grid grid-rows-2 items-center gap-4 mb-3">
              <Label className="justify-self-center" htmlFor={"code"}>Enter the evaluation code:</Label>
              <Input id="code" {...form.register("code")} name="code"/>
            </div>
            <FormMessage />
            <div className=" flex justify-center">
              <Button className="bg-teal100 hover:bg-blue100" asChild>
                <input type="submit" value={'Submit'}/>
              </Button>
            </div>
            </FormItem>
          )}
        />
          </form>
          </Form>
          </div>
          
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default AddCampaign;