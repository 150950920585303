import { Track } from 'openapi/api/model/track';
import { createContext, useState } from 'react';


export const AuthContext = createContext({
    track: undefined as Track|undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setTrack: (_track:Track|undefined)=>{},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AuthContextProvider = ({children}: any) => {
    const [authUserTrack, setAuthUserTrack] = useState<Track>();
    const setTrack = (track:Track|undefined) => {
        setAuthUserTrack(track);
        console.log('CTx',authUserTrack)
    }


    const value = {
        track: authUserTrack,
        setTrack: setTrack,
    }

    return(
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;