import { useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Button } from "@/components/ui/button"
import { ScrollArea } from "@/components/ui/scroll-area"
import { CheckCircle2, Clipboard, ClipboardCheck, XCircle } from "lucide-react"
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel"
import { Separator } from "@/components/ui/separator"
import { PageInteractions, SingleAction, UserStoryFrontend } from 'openapi/api/model'
import Timer from '@/components/ui/custom/Timer'
import { WorkspaceInfo } from '../../WorkspaceInfo'
import { usePostRuntest } from '../../Backend/utils/hooks'
import { toast } from 'sonner'
import CircularProgress from '@mui/joy/CircularProgress'
import { ProjectContextFrontend } from '../../../../../../../openapi/api/handMadeTypes/ProjectContextFrontend';


const FrontendProjectProposal = ({context, workspaceUrl, skill, timerDone}:{context?:ProjectContextFrontend, workspaceUrl:string, skill:string, timerDone:() =>void}) => {
  const [tests, setTests] = useState<{ description:string, hasPassed:boolean }[]>([])
  const [copied, setCopied] = useState(false);
  const [checks, setChecks] = useState([
    { id:1, text: 'I have implemented all required features', completed: false },
    { id:2, text: 'I have tested my implementation thoroughly', completed: false },
    { id:3, text: 'I have pushed my code to the repository', completed: false }
  ]);
  const {mutate:runtest,isPending} = usePostRuntest({
    onSuccess:(data)=>{
      setTests(data)
      toast.success("Tests ran successfully!",{
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#a3e635'
        }
      })
    },
    onError: ()=>{
      toast.error("Failed to run tests!",{
        description: "Ensure your app is running properly",
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
    }
  })
  
  const toggleCheckbox = (id:number) => {
    setChecks((prevChecks) =>
      prevChecks.map((check) =>
        check.id === id ? { ...check, completed: !check.completed } : check
      )
    );
  };

  const onRunTest = () => {
    runtest({data:{
      templateId: context?._id??'',
      type: 'frontend',
      url: workspaceUrl.split('?')[0]+'proxy/8080/',
      skill: skill??''
    }})
  }

  const copyHandler = () => {
    navigator.clipboard.writeText('chmod +x setup.sh && ./setup.sh');
    setCopied(true);
  };

  return (
    <div className="w-full min-h-screen bg-[#101820] text-[#858C92] flex flex-col">
      <div className="p-6 bg-[#545454] shadow-lg">
        <div className="relative max-w-6xl mx-auto">
          <WorkspaceInfo/>
          <h1 className="text-3xl font-bold text-[#26A69A] mb-2">{context?.ProjectName}</h1>
          <p className="text-[#ccfbf1] opacity-80 mb-4">
            Keep this page open at all times during the evaluation. Do not leave this page before pushing your code!
          </p>
          <h1 className="text-[#ccfbf1] opacity-80 mb-4">Link to the workspace: <a href={workspaceUrl} target='_blank'><u>{workspaceUrl}</u></a> </h1>
          <h1 className="text-[#ccfbf1] opacity-80 mb-4">To run setup/install dependencies and start dev server, paste these commands in the terminal:</h1>
          <pre 
            className="flex flex-row justify-between w-1/3 mb-4 p-4 bg-[#101820] rounded-md border border-[#26A69A] border-opacity-30"
            onClick={copyHandler}
          >
            <code className="flex items-center w-full text-sm text-teal-300 truncate">chmod +x setup.sh && ./setup.sh</code>
            {copied ? <ClipboardCheck/> : <Clipboard/>}
          </pre>
          <div className="flex justify-between items-center">
            <Timer timerMinutes={45} timerDone={timerDone}/>
          </div>
        </div>
      </div>

      <div className="flex-grow p-6">
        <div className="max-w-6xl mx-auto">
          <Tabs defaultValue="overview" className="w-full">
            <TabsList className="w-full bg-[#545454] p-1 rounded-lg mb-6 flex">
              {["overview", "instructions", "test", "submission"].map((tab:string) => (
                <TabsTrigger
                  key={tab}
                  value={tab}
                  className="flex-1 text-[#ccfbf1] capitalize py-2 px-4 rounded-md data-[state=active]:bg-[#26A69A] data-[state=active]:text-[#101820] transition-all duration-200"
                >
                  {tab}
                </TabsTrigger>
              ))}
            </TabsList>
            <div className="mt-6 w-full">
              <TabsContent value="overview">
                <Card className="w-full bg-[#545454] border-[#26A69A] shadow-lg">
                  <CardHeader>
                    <CardTitle className="text-2xl text-[#26A69A]">Project Overview</CardTitle>
                  </CardHeader>
                  <CardContent className="text-[#ccfbf1]">
                    <Carousel className="w-full max-w-xl mx-auto mb-6">
                      <CarouselContent>
                        {context?.userStories?.map((userStory:UserStoryFrontend, index:number) => 
                          userStory?.images?.map((img) => <CarouselItem key={index}>
                            <img src={img} className="w-full h-auto rounded-lg" />
                          </CarouselItem>)
                        )}
                      </CarouselContent>
                      <CarouselPrevious className="text-[#ccfbf1] hover:text-[#26A69A]" />
                      <CarouselNext className="text-[#ccfbf1] hover:text-[#26A69A]" />
                    </Carousel>
                    {["Objective", "Features", "Milestones", "Development Tools"].map((section) => (
                      <div key={section} className="mb-6">
                        <h3 className="text-lg font-semibold mb-2 text-[#26A69A]">{section}</h3>
                        <ul className="list-disc list-inside space-y-1">
                          {section === "Objective" ? (
                            <li>{context?.ProjectProposal?.Objective}</li>
                          ) : section === "Features" ? (
                            context?.ProjectProposal?.KeyFeatures?.map((item:string, index:number) => (
                              <li key={index}>{item}</li>
                            ))
                          ) : section === "Milestones" ? (
                            context?.ProjectProposal?.TechnicalApproach?.Milestones?.map((item:string, index:number) => (
                              <li key={index}>{item}</li>
                            ))
                          ) : (
                            <li>{context?.ProjectProposal?.TechnicalApproach?.DevelopmentTools}</li>
                          )}
                        </ul>
                      </div>
                    ))}
                  </CardContent>
                </Card>
              </TabsContent>
              <TabsContent value="instructions">
                <Card className="w-full bg-[#545454] border-[#26A69A] shadow-lg">
                  <CardHeader>
                    <CardTitle className="text-2xl text-[#26A69A]">Page Instructions</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <ScrollArea className="h-[600px] w-full rounded-md p-4">
                      {context?.userStories?.map((userStory:UserStoryFrontend, pageIndex:number) => (
                        <div key={pageIndex} className="mb-8">
                          <h3 className="text-xl font-semibold mb-4 text-[#26A69A]">Page Url: "{userStory?.interactions?.[0].pageUrl}"</h3>
                          <Carousel className="w-full max-w-xl mx-auto mb-6">
                            <CarouselContent>
                              {userStory?.images?.map((img:string, imgIndex:number) => (
                                <CarouselItem key={imgIndex}>
                                  <img src={img} className="w-full h-auto rounded-lg" />
                                </CarouselItem>
                              ))}
                            </CarouselContent>
                            <CarouselPrevious className="text-[#ccfbf1] hover:text-[#26A69A]" />
                            <CarouselNext className="text-[#ccfbf1] hover:text-[#26A69A]" />
                          </Carousel>
                          {userStory?.interactions?.map((interaction:PageInteractions, interactionIndex:number) => 
                          interaction.actionSequence?.actions?.map((action:SingleAction) =>
                            <div key={interactionIndex} className="mb-4 p-4 bg-[#101820] rounded-lg border border-[#26A69A] border-opacity-30">
                              <p className="font-semibold mb-2 text-[#26A69A]">{interaction?.actionSequence?.description}</p>
                              <p><span className="font-medium text-[#26A69A]">Action Type:</span> <span className="text-[#ccfbf1]">{action.action}</span></p>
                              <p><span className="font-medium text-[#26A69A]">Selector:</span> <code className="bg-[#545454] px-2 py-1 rounded text-[#ccfbf1]">[data-testId='{action.selector}']</code></p>
                              {action.input && <p><span className="font-medium text-[#26A69A]">Input:</span> <code className="bg-[#545454] px-2 py-1 rounded text-[#ccfbf1]">{action.input}</code></p>}
                              <p><span className="font-medium text-[#26A69A]">Instruction:</span> <span className="text-[#ccfbf1]">{action.instructions}</span></p>
                            </div>
                          ))}
                          {pageIndex < (context?.userStories?.length ??  0 ) - 1 && (
                            <Separator className="my-8 bg-[#26A69A] opacity-30" />
                          )}
                        </div>
                      ))}
                    </ScrollArea>
                  </CardContent>
                </Card>
              </TabsContent>
              <TabsContent value="test">
                <Card className="w-full bg-[#545454] border-[#26A69A] shadow-lg">
                  <CardHeader>
                    <CardTitle className="text-2xl text-[#26A69A]">Test Your Implementation</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <Button 
                      onClick={onRunTest} 
                      disabled={isPending}
                      className={`w-full bg-[#26A69A] text-[#101820] ${isPending && `text-white`} hover:bg-opacity-90 py-2 rounded-md font-semibold transition-colors duration-200 mb-6`}
                    >
                      {isPending ? "Running Tests..." : "Run Tests"}
                    </Button>
                    <div className="space-y-4">
                      <div className='flex justify-center'>
                        {isPending && <CircularProgress variant="soft" sx={{"--CircularProgress-progressColor":'#26A69A'}}/>}
                      </div>
                      {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      tests?.map((test:any) => (
                        <div key={test} className="flex items-center justify-between p-4 bg-[#101820] rounded-lg">
                          <span className="text-[#ccfbf1]">{test.description}</span>
                          {test === null ? (
                            <span className="text-[#858C92]">N/A</span>
                          ) : test.hasPassed ? (
                            <CheckCircle2 className="w-6 h-6 text-green-500" />
                          ) : (
                            <XCircle className="w-6 h-6 text-red-500" />
                          )}
                        </div>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
              <TabsContent value="submission">
                <Card className="w-full bg-[#545454] border-[#26A69A] shadow-lg">
                  <CardHeader>
                    <CardTitle className="text-2xl text-[#26A69A]">Submission Checklist</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="space-y-4">
                      {checks.map((check) => (
                        <div key={check.id} className="flex items-center space-x-3">
                          <input 
                          //id={`${check.id}`}
                          type='checkbox' 
                          checked={check.completed}
                          onChange={() => toggleCheckbox(check.id)}
                          style={{
                            height: '1rem',  // equivalent to h-4
                            width: '1rem',   // equivalent to w-4
                            border: '1px solid #26A69A',
                            borderRadius: '0.125rem', // equivalent to rounded-sm
                            accentColor: '#26A69A' // for modern browsers that support it
                          }} />
                          <label
                            htmlFor={`${check.id}`}
                            className="text-sm font-medium leading-none text-[#ccfbf1] peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                          >
                            {check.text}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardContent>
                  <CardFooter>
                    <Button 
                    onClick={timerDone} 
                    disabled={!checks.every(check => check.completed)}
                    className="w-full bg-[#26A69A] text-[#101820] hover:bg-opacity-90 py-2 rounded-md font-semibold transition-colors duration-200">
                      Submit Evaluation
                    </Button>
                  </CardFooter>
                </Card>
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
export default FrontendProjectProposal