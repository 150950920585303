import axios from 'axios';
import { z } from 'zod';

interface CreateRepoProps{
  repoName:string, 
  sub:string, 
  trackID:string,
  projectID:string,
  hiringCampaignId:string,
  owner:string,
  skill:string,
  templateId:string
}

type MongoDBObjectId = string;

interface Repository {
  sub:string,
  repoName: string,
  trackID: MongoDBObjectId,
  projectId: MongoDBObjectId,
  hiringCampaignId: MongoDBObjectId,
  orgName: string,
  username: string,
  skill:string,
  templateId:string
}

export const SupportedSkillsSchema = z.enum([
  "frontend-react",
  "frontend-angular",
  "frontend-vue",
  "backend-typescript",
]);

export const CreateRepoSchema = z.object({
  repoName: z.string().min(1).refine(item => !!item, { message: 'repoName is invalid' }),
  repo_path: z.string().optional(),
  projectId: z.string().min(1).refine(item => !!item, { message: 'projectId is invalid' }),
  templateId: z.string().min(1).refine(item => !!item, { message: 'templateId is invalid' }),
  trackID: z.string().min(1).refine(item => !!item, { message: 'trackID is invalid' }),
  hiringCampaignId: z.string().min(1).refine(item => !!item, { message: 'hiringCampaignId is invalid' }),
  skill: SupportedSkillsSchema.refine(item => !!item, { message: 'skill is invalid' }),
  orgName: z.string().min(1).refine(item => !!item, { message: 'orgName is invalid' }),
  sub: z.string().min(1).refine(item => !!item, { message: 'sub is invalid' }),
  username: z.string().min(1).refine(item => !!item, { message: 'username is invalid' }),
}).strict();

const createRepo =async (createRepoArgs:CreateRepoProps) =>{
  const {repoName,sub,trackID,projectID,owner,skill,templateId,hiringCampaignId}=createRepoArgs
  const payload =(
    {
      repoName,
      trackID,
      projectId: projectID,
      hiringCampaignId,
      skill,
      orgName:'TheDevFactory-Pilot',
      templateId,
      username: owner
      
    } as Repository);
  return await axios.post(`https://api.apisdevf.net/experimental_ghworker/createSonarProjectWithYaml/${sub}`, payload);

}

export default createRepo;