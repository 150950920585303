/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react"
import evaluateCode from "./evaluateCode";
import { TestCases } from './contexts';


declare global {
    interface Window {
        loadPyodide?: any;
    }
  }
  
export const usePython = ({tests}:{tests:TestCases[]}) => {
    const [output, setOutput] = useState<string>('');
    const [pyodide, setPyodide] = useState<any>(null);

    useEffect(() => {
        // Load Pyodide when the component mounts
        window.loadPyodide()
        .then((pyodideInstance:any) => {
            setPyodide(pyodideInstance);
        })
        .catch((err:Error)=>{
            setOutput(('Failed to load pyodide, please try compiling you code again'))
            throw new Error(err.message)
        })
    }, []);

    const runPythonCode = async ({code}:{code:any}) => {
        // Initialize logs to capture printed output
        const capturedOutput:string[] = [];
        
        if (pyodide) {
            try {
                // Redirect stdout to capture printed output
                pyodide.setStdout({
                    // Custom print function to collect printed outputs
                    batched: (text:string) => {
                        capturedOutput.push(text);
                    }
                });

                
                // Prepare the Python code
                const finalCode = evaluateCode({ projectContext: tests as any, incomingCode: code });
                
                // Run the Python code
                await pyodide.runPythonAsync(finalCode);
                setOutput(capturedOutput.join('\n'))
                //console.log({result: capturedOutput})



                return({result: capturedOutput.join('\n')})
                
            } catch (error) {
                setOutput((error as Error).message??error)
            } finally {
                // Reset stdout to default
                pyodide.setStdout(console.log);
            }
        } else {
            setOutput('Pyodide is not loaded yet');
            window.loadPyodide()
            .then((pyodideInstance:any) => {
                setPyodide(pyodideInstance);
            })
            .catch((err:Error)=>{
                setOutput(err.message)
                throw new Error(err.message)
            })
    }}
    return {
        output,
        runPythonCode,
        pyodide
    };
};
export default usePython;













