import { queryClient, rootRoute } from "@/App"
import LinksContainer from "@/components/Navigation/LinkContainer"
import SideBar from "@/components/Navigation/SideBar"
import { Outlet, Route, redirect, useNavigate } from "@tanstack/react-router"
//import { useQueryClient } from "@tanstack/react-query"
import Header from "@/components/ui/Header"
import { Button } from "@/components/ui/button"
import { useGetUserDeveloper } from "../../openapi/api/endpoints/default/default"
import { customClient } from "../../openapi/mutator/custom-client"
import { Toaster } from "sonner"
import TawkChat from "@/store/Tawk"
import { ObjectType } from "@/pages/Authentication/AuthUtils/authTypes"

export const MainApp =  () => {
  const navigate=useNavigate({from:'/'})
  const resp=queryClient.getQueryData(['Auth']) as ObjectType
    const session=resp?.session
    const token=session?.idToken
    console.log('Token: ',token)
  const { data, isLoading }=useGetUserDeveloper()
  let content = <Outlet />;

  if (!data?.sub || data?.sub === undefined) {
    if (isLoading) {
      content = (
        <div className="flex flex-col basis-1/3 bg-gray-100 min-h-screen items-center place-content-center border-red-300">
          <h1>Loading...</h1>
        </div>
      );
    }
  }

  const logOut=async ()=>{
    //queryClient.setQueryData(['Auth'],null)
    //queryClient.removeQueries()
    try{
      queryClient.clear()
      queryClient.setQueryData(['/status'],'')
      await customClient({
        url:'/auth/logout',
        method:'POST'
      })
    }catch(e){
      throw({signoutErr:e})

    }
    setTimeout(()=>{
      navigate({to:'/auth',from:'/'})
      location.reload()
    },1000)
  }
  return (
    <>
    <SideBar>
      <div className="mb-5 ml-2">
        <Header>THE DEV</Header>
      </div>
      <div className="basis-10/12">
        <LinksContainer //add link to start project
        links={[
          {to:"/home",title:"Home"},
          {to:"/overview",title:"Overview"}
        ]}/>
        {/* <div className="pb-4">
          <ProjectSearchBar/>
        </div> */}
      </div>
      
      <div className="grid gap-2 grid-cols-1 basis-1/6">
        <Button onClick={logOut} className="bg-primary100 rounded-full hover:bg-teal100">Log out</Button>
      </div>
    </SideBar>
    <div className="sm:ml-64">
      {content}
      <div>
        <TawkChat/>
      </div>
    </div>
    
    <Toaster/>
    </>
  )
    }
    

export const MainAppRoute=new Route({
    component:MainApp,
    getParentRoute:()=> rootRoute,
    path:'/',
    beforeLoad: async () => {
      await queryClient.ensureQueryData({
        queryKey:['/status'],
        queryFn:async ()=>{
          return customClient<{status:'Unauthorized' | 'loggedIn'}>({
            url:'/auth/status',
            method:'GET'
          })
        },
        staleTime:10*60*1000 //10 minutes
      })
      const status=queryClient.getQueryData(['/status']) as {status:'Unauthorized' | 'loggedIn'}
      console.log({status})
      if (!status || status.status==='Unauthorized') {
        throw redirect({
          to: "/auth",
          replace: true,
        });
      }
    },
      
})