import { Button } from '@/components/ui/button'
import { CardContent } from '@/components/ui/card'
import { checkSEB } from '@/pages/Project/CreateProject/utils/checkSEB'
import { useNavigate } from '@tanstack/react-router'
import { AxiosError } from 'axios'
import { GetEvaluationprogress200OngoingEvalProgressionItem } from 'openapi/api/model'

export const TimelineNavigator = ({projectFromTrack}:{projectFromTrack:GetEvaluationprogress200OngoingEvalProgressionItem}) => {
    const navigate=useNavigate({from:'/'})

    const startEvaluation = async()=>{

        try{
            await checkSEB()
            navigate({
                to:`/course/${projectFromTrack.projectID}/${projectFromTrack.skill}/${projectFromTrack.evalType}`,
                params:{courseId:projectFromTrack.projectID}
            })
        }catch(err){
            console.log('SEB check: ', err)
            if((err as AxiosError).response?.data==='Please use the provided SEB'){
                navigate({to:`/course/${projectFromTrack.projectID}/downloadSEB`})
            }else{
                throw err
            }
        }
    }

    return (
        <button 
            className="flex-1 bg-gray-200 mb-4 rounded-xl border w-full" 
            >
            <CardContent className="p-2">
                <div className="flex h-auto p-2 w-full">
                    
                    
                    <div className='flex flex-col h-full w-3/4 pl-[20px]'>
                        <div className="flex w-full font-bold text-lg text-black max-xl:truncate">{projectFromTrack.projectName}</div>
                        <div className="pl-1 flex w-full items-center font-normal text-black max-xl:truncate">
                        Next evaluation: {projectFromTrack.skill} ({projectFromTrack.evalType})
                    </div>
                    </div>     
                    <div className='flex w-1/4 ml-2'>
                        <Button 
                            className='h-full w-full bg-teal100 hover:bg-blue100'
                            onClick={startEvaluation}>Start</Button>
                    </div>                             
                </div>
            </CardContent>
        </button>
    )
}
