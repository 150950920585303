import { rootRoute } from "@/App"
import TabsWrapper from "@/components/ui/TabsWrapper"
import { Route, useNavigate } from "@tanstack/react-router"
import {SignUp} from "./AuthComponents/SignUp"
import { Login } from "./AuthComponents/Login"
import { useState } from "react"
import logo2 from "../../assets/logo2.png"
import { Toaster } from "@/components/ui/sonner"

const Authentication = () => {
    const navigate = useNavigate({from: "/"})
    const [confirmationStage, setConfirmationStage] = useState(false);

    const disableTabs = () => {
        setConfirmationStage(true);
    }
  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-blue100">
        <img src={logo2} className="h-[100px] w-[175px] m-4 rounded-lg"/>
        <TabsWrapper
        tabsComponents={[
            {
                tabsTitle:"Log in",
                Component:<Login/>
            },
            {
                tabsTitle:"Sign up",
                Component:<SignUp setConfirmationStage={disableTabs}/>
            },
        ]}
        defaultValue="Log in"
        disabled={confirmationStage}
        />
        <button className="text-white text-2xs underline mt-2" onClick={()=>navigate({to: "/passwordReset"})}>Forgot password?</button>
        <Toaster/>
    </div>
  )
}

const AuthRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/auth",
    component: Authentication,
})
export default AuthRoute