/* eslint-disable @typescript-eslint/no-explicit-any */
const processAlgoEvalOutput = ({capturedOutput}: {capturedOutput: string}) => {
    const jsonObjects: any[] = [];
  
    // Split the captured output into parts that can be individually parsed
    const jsonObjectsRaw = capturedOutput.split('\n').filter(line => line.trim().length > 0);
  
    jsonObjectsRaw.forEach(jsonString => {
        const sanitizedJsonString = jsonString
            .replace(/'/g, '"') // Replace single quotes with double quotes
            .replace(/None/g, 'null') // Replace Python's None with JSON's null
            .replace(/True/g, 'true') // Replace Python's True with JSON's true
            .replace(/False/g, 'false'); // Replace Python's False with JSON's false
  
        try {
            const parsedObject = JSON.parse(sanitizedJsonString);
            jsonObjects.push(parsedObject);
        } catch (error) {
            console.error('Error parsing JSON object:', sanitizedJsonString, error);
        }
    });
  
    return jsonObjects;
};


const returnTestCoverage=(result:{result:string})=>{
    try {
        console.log('code compilation result', { output: result });
        const processedOutput = processAlgoEvalOutput({ capturedOutput: result.result }); // Use the result directly
        const testCasesLength=processedOutput.filter((testResult)=> testResult.functionName).length
        const coverage = processedOutput.filter((testResult) => testResult.isAdequate).length;
        console.log({ coverage,testCasesLength });
        // Ensure testCasesLength is not zero to avoid NaN
        if (testCasesLength === 0) {
            return 0; // No test cases, return 0 coverage
        }
        return ((coverage/testCasesLength)*100)
    } catch (err) {
        console.log(err);
    }

}
  

export default returnTestCoverage