const NotFoundComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
      <h1 className="text-2xl font-bold">404 - Page Not Found</h1>
      <p className="font-bold">The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFoundComponent;
