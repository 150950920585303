import { useMutation, useQueryClient } from "@tanstack/react-query"
import { z } from "zod";
import { SignUpPropsSchema } from "./authTypes";
import { customClient } from '../../../../openapi/mutator/custom-client';
export const login=async ({userName,password}:{userName:string,password:string})=>{
    return customClient({
        url:'/auth/login',
        method:'POST',
        data:{userName,password}
    })
}


const signUp=async (signUpParams:z.infer<typeof SignUpPropsSchema>)=>{
    return customClient({
        url:'/auth/signup',
        method:'POST',
        data:signUpParams
    })
}

const confirmSignUp=async ({userName,code, password}:{userName:string,code:string,password:string})=>{
    return customClient({
        url:'/auth/confirmSignup',
        method:'POST',
        data:{userName,code,password}
    })
}

export const resendConfirmationCode=async ({userName}:{userName:string})=>{
    return customClient({
        url:'/auth/resendConfirmationCode',
        method:'POST',
        data:{userName}
    })
}

/**
 * Logs user in and returns auth cookies
 * @returns **idToken,refreshToken** cookies
 */
export const useLoginExperimental=({onSuccess}:{onSuccess:()=>void})=>{
    const queryClient=useQueryClient()
    return useMutation({
        mutationKey:['Auth_Exp'],
        mutationFn:login,
        onSuccess:async (data)=>{
            queryClient.setQueryData(['Auth'],data);
            onSuccess();
        },
    })
}

/**
 * Signs user up into cognito user pool; user needs to be confirmed to move forward
 */
export const useSignUpExperimental=()=>{
    return useMutation({
        mutationFn:signUp

    })
}
/**
 * Confirms cognito user through userName and emailed code and then logs in user
 * @returns **idToken,refreshToken** cookies
 */
export const useConfirmSingup=()=>{
    const queryClient=useQueryClient()
    return useMutation({
        mutationKey:['Auth_Exp'],
        mutationFn:confirmSignUp,
        onSuccess:async (data)=>{
            queryClient.setQueryData(['Auth'],data);
        },
    })
}

/**
 * resends confirmation code to user email
 */
export const useResendCodeExperimental=()=>{
    return useMutation({
        mutationFn:resendConfirmationCode
    })
}
