import { Route } from '@tanstack/react-router';
import { rootRoute } from '@/App';


export const ApplicationSubmitted = () => {
  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center bg-background">
        <div className="bg-white rounded-lg shadow-lg p-8 text-center">
          <CircleCheckIcon className="text-green-500 h-16 w-16 mx-auto mb-4" />
          <h2 className="text-2xl font-bold mb-2">Application Submitted!</h2>
          <p className="text-muted-foreground">
            Thank you for applying. We will review your application and get back to you soon.
          </p>
        </div>
    </div>
  )
}

function CircleCheckIcon(props:any) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10" />
        <path d="m9 12 2 2 4-4" />
      </svg>
    )
  }

const ApplicationSubmittedRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/apply/$hiringcampaignId/submitted",
    component: ApplicationSubmitted,
});


export default ApplicationSubmittedRoute