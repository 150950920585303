import { Card } from '@/components/ui/card';
import { useGetProjectContext, useGetUserDeveloper } from '../../../../../../openapi/api/endpoints/default/default';
import FrontendProjectProposal from '../Frontend/components/FrontendProjectProposal';
import BackendProjectProposal from './BackendProjectProposal';
import { ProjectContextBackend } from 'openapi/api/model/projectContextBackend';
import { ProjectContextFrontend } from '../../../../../../openapi/api/handMadeTypes/ProjectContextFrontend';
import { frameworkType } from '@/utils/Types/skills';

const ProjectSteps=({theProjectID,templateId,skill, blockerFn}:{theProjectID:string,templateId:string,skill:string, blockerFn:() => void})=>{

    const { data:context } = useGetProjectContext({templateId:templateId??'',skill:skill??''})

    const {data:userData} = useGetUserDeveloper();
    const workspaceUrl = 'http://'+userData?.userName+'-'+theProjectID+'.workspace.apisdevf.net/?folder=/config/workspace'
    let content
    
    const goToOverview= () => {
        blockerFn();
    }

    switch (true) {
        case frameworkType["backend"].includes(skill):
            content = <BackendProjectProposal context={context as ProjectContextBackend} workspaceUrl={workspaceUrl} skill={skill} timerDone={goToOverview}/>;
            break;
        case frameworkType["frontend"].includes(skill):
            content = <FrontendProjectProposal context={context as ProjectContextFrontend} workspaceUrl={workspaceUrl} skill={skill} timerDone={goToOverview}/>;
            break;
        default:
            content = <div></div>;
            break;
    }
       
    return (
        <div className=" bg-gray-100 min-h-screen" style={{alignItems: 'center'}}>
            <Card className="flex flex-col pl-4 w-full items-center justify-center border-none m-6" style={{backgroundColor: '#263238', padding: '10px', borderRadius: '8px', alignSelf:'center' }}> 
                {content}
            </Card>
        </div>
    );
}

export default ProjectSteps;