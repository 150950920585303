import { rootRoute } from "@/App"
import { Route, useLocation } from "@tanstack/react-router"
import { Toaster } from "@/components/ui/sonner"
import { ResumeSignUp } from "./AuthComponents/ResumeSignUp"

export const ResumeSignUpPage = () => {
  //const { username, access } = useParams({ from: "/" }); 
  //const [confirmationStage, setConfirmationStage] = useState(false);
  const location = useLocation(); // Hook to access location object

  // Parse query parameters from location.search
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get("username");
  const email = queryParams.get("email");
  const access = queryParams.get("access");

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-r from-blue-900 to-cyan-300">
      <ResumeSignUp username={username??''} pwd={access??''} email={email??''}/>
      <Toaster/> 
    </div>
  )
}

// /resume?username=Jp11&access=12345Abc

const ResumeSignUpPageRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/resume",
    component: ResumeSignUpPage,
})
export default ResumeSignUpPageRoute