type AlgoEvalProjectContext={
    functionName: string,
    parameters: object[],
    expectedOutput: object[]

}

const evaluateCode = ({ projectContext, incomingCode }: { projectContext: AlgoEvalProjectContext[], incomingCode: string }) => {
    let finalCode = incomingCode; // Start with the incoming code

        // Check if all desired functions are present in the incoming code
        for (const { functionName } of projectContext) {
            // Check if the function is present in the incomingCode using regex
            const functionRegex = new RegExp(`def\\s+${functionName}\\s*\\(`);
            if (!functionRegex.test(incomingCode)) {
                // If any desired function is missing, return the original incoming code
                return incomingCode;
            }
        }
    

    // Define the utility function in Python
    const utilityFunction = `
        \ndef evaluate_function(func_name, params, possible_outputs):
    result = None  # Ensure result is always defined
    try:
        result = globals()[func_name](*params)
        is_adequate = result in possible_outputs or (not possible_outputs and result == [])
        return {
            "functionName": func_name,
            "parameters": params,
            "possibleOutputs": possible_outputs,
            "isAdequate": is_adequate,
            "result": result
        }
    except Exception as e:
        return {
            "functionName": func_name,
            "parameters": params,
            "possibleOutputs": possible_outputs,
            "isAdequate": False,
            "error": str(e),
            "result": result  # result will still be defined as None
        }

        `;

    // Append the utility function to the candidate's code
    finalCode += `\n${utilityFunction}\n`;

    // Initialize a list in Python to store all results
    finalCode += `results = []\n`;

    // Add function calls for each context in projectContext
    for (const { functionName, expectedOutput, parameters } of projectContext) {
      //        \nresults.append(evaluate_function("${functionName}", ${JSON.stringify(parameters)}, ${JSON.stringify(expectedOutput)}))
        finalCode += `
        \nprint(evaluate_function("${functionName}", ${JSON.stringify(parameters)}, ${JSON.stringify(expectedOutput)}))
        `;
        }

    // Print all the results
    //finalCode += `\nprint(results)\n`;

    return finalCode;
};


export default evaluateCode

