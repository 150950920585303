import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Progress } from "@/components/ui/progress"
import { CheckCircle, XCircle } from "lucide-react"

interface UnitTestResultProps {
  coverage: number
  onSubmit: () => void
}

export default function Component({ coverage, onSubmit }: UnitTestResultProps) {
  const isPassingCoverage = coverage >= 75

  return (
    <Card className="w-full bg-mint-100">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">Unit Test Results</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col justify-between">
        <div>
          <div className="flex items-center justify-between mb-2">
            <span className="text-lg font-medium">Coverage:</span>
            <span className="text-2xl font-bold">{coverage}%</span>
          </div>
          <Progress 
            value={coverage} 
            className={`w-full h-2 mb-4 bg-gray-200 ${isPassingCoverage ? 'bg-green-500' : 'bg-red-500'}`} 
          />
        </div>
        <div className="flex items-center space-x-2 mb-4">
          {isPassingCoverage ? (
            <CheckCircle className="w-6 h-6 text-green-500 flex-shrink-0" />
          ) : (
            <XCircle className="w-6 h-6 text-red-500 flex-shrink-0" />
          )}
          <span className="text-sm">
            {isPassingCoverage
              ? "Great job! Your code has good test coverage."
              : "Your code needs more test coverage. Aim for at least 75%."}
          </span>
        </div>
        <Button onClick={onSubmit} className="w-full mt-4">
          Submit Assignment
        </Button>
      </CardContent>
    </Card>
  )
}