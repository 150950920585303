import { Send } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import Timer from '@/components/ui/custom/Timer'
import { useForm } from 'react-hook-form'
import useChat from '@/utils/Websocket/useChat'
import ChatWindow from '@/components/Chat/ChatWindow'
import { useState } from 'react'

const theme = {
  mint100: '#ccfbf1',
  teal100: '#26A69A',
  blue100: '#101820',
}


const ChatEvaluation = ({sub,projectId,hiringCampaignId,trackId,skill,blockerFn}:{sub:string,projectId:string,hiringCampaignId:string,trackId:string,skill:string,blockerFn:() => void}) => {
  const {register, handleSubmit, setValue}=useForm()
  const [botWriting, setBotWriting] = useState<boolean>(false)
  //const [currentIndex, setCurrentIndex] = useState(0)
  const onSendMessage=()=>{
    setBotWriting(true);
    setValue('ClientMessage','')
    
  }

  const onReceivingMessage=()=>{
    setBotWriting(false); 
  }

  const {currentMessages,sendMessage}=useChat({onSendMessage,onReceivingMessage,sub,projectId,hiringCampaignId,trackId,skill})

  const goToOverview= () => {
    blockerFn();
  }


  return (
    <div className="flex flex-col py-2 px-32 bg-gray-100 h-[100dvh] max-md:w-screen overflow-x-auto">
      <Card className="flex-auto w-full h-full flex flex-col" style={{ backgroundColor: theme.blue100, color: theme.mint100 }}>
          <CardHeader className="flex-shrink-0 items-center">
              <CardTitle className="text-center text-white">Evaluation Chat</CardTitle>
              <Timer timerMinutes={45} timerDone={goToOverview}/>
            </CardHeader>
            <CardContent className="flex-grow overflow-hidden">
                <ChatWindow conversation={currentMessages}/>
            </CardContent>
            <CardFooter className="flex-shrink-0">
                <form className="flex grow w-full" onSubmit={handleSubmit(sendMessage)}>
                    <Input
                    id="message"
                    placeholder="Send a message..."
                    className=" mr-2 text-white bg-gray-600 border-none"
                    {...register('ClientMessage')}/>
                    
                    {botWriting && <Button disabled
                      type='submit'
                      style={{ backgroundColor: theme.teal100, color: theme.blue100 }}
                    >
                      <Send className="h-4 w-4" />
                      <span className="sr-only">Send</span>
                    </Button>}
                    {!botWriting && <Button 
                      type='submit'
                      style={{ backgroundColor: theme.teal100, color: theme.blue100 }}
                    >
                      <Send className="h-4 w-4" />
                      <span className="sr-only">Send</span>
                    </Button>}
                </form>
            </CardFooter>
        </Card>
    </div>
  )
}

export default ChatEvaluation;