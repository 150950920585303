//import { useQueryClient } from "@tanstack/react-query"
import { AuthContext } from '../../store/track-context';
import { useContext } from 'react';
//import { Track } from "openapi/api/model"

//import { useState } from "react"
import { useGetTracks } from '../../../openapi/api/endpoints/default/default';
import DropDownSelect from "@/components/ui/custom/DropDownSelect";
import { useNavigate } from '@tanstack/react-router';

export const TrackSelect=()=>{
  const navigate = useNavigate({from:'/'})
  const {setTrack,track} = useContext(AuthContext)
  const {data:tracks, isLoading}=useGetTracks()

  const handleSelect=({ itemID }:{ itemID:string })=>{
    setTrack(tracks?.find((track)=>track._id===itemID ))
    navigate({to:'/overview'})
  }

  if(isLoading || !tracks){
    return <div>...loading</div>
  }

  let i:number = 1

  return (
  <DropDownSelect
    data={tracks.map((track)=>{
      return {
        itemKey:track._id as string,
        itemValue:`${i++} - ${track.trackName} @${track.companyName}`
      }
    })}
    initialValue={track?.trackName??"All Evaluations"}
    onSelect={handleSelect}
    dropDownMenuLabel="Search tracks"
/>
  )
}

