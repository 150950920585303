/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from 'react-hook-form';
import { useRef, useState } from 'react';
import * as z from 'zod';
import { SignupSchema } from "@/utils/FormUtils/FormSchema";
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { CardWithNoFooter } from "@/components/ui/CardWithNoFooter";
import { AuthCard } from '@/components/Auth/AuthCard';
import FormElements from "@/components/ui/FormElements";
import { useQueryClient } from '@tanstack/react-query';
import { LinearProgress } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
//import { usePostUserDeveloper } from 'openapi/api/endpoints/default/default';
//import { AuthenticateUserResp } from '../AuthUtils/Login';
import { DevUser } from 'openapi/api/model';
import { toast } from 'sonner';
import { Toaster } from '@/components/ui/sonner';
import { AxiosError } from 'axios';
import { resendConfirmationCode, useConfirmSingup, useSignUpExperimental } from '../AuthUtils/authHooks';
import { useCreateAccount } from '@/utils/Auth/Session';
import { ObjectType } from '../AuthUtils/authTypes';
//import { CognitoUser } from 'amazon-cognito-identity-js';



export const SignUp = ({setConfirmationStage}:{setConfirmationStage:()=>void}) => {
  
  const { register, handleSubmit, formState: {errors}, getValues } = useForm<z.infer<typeof SignupSchema>>({
    resolver: zodResolver(SignupSchema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    }
  });

  const navigate=useNavigate({from:'/auth'})
  const [,setSignupCodeStatus]=useState<'idle' | 'in progress'>('idle')
  const queryClient=useQueryClient()
  const resp=queryClient.getQueryData(['Auth']) as ObjectType
  const sub=resp?.cognitoUser.signInUserSession?.idToken.payload.sub as string
  console.log('Sub: ', sub)
  const devUser:DevUser={
    email:getValues('email'),
    userName:getValues('userName'),
    sub:sub,
    github_ID:getValues('userName'),
    bio:'Your bio/experience'
  }
  //const {mutate:Login,isSuccess:loginSuccess}=useSignIn({queryClient})
  const {createAccount,errors:createAccountErrs,loading}=useCreateAccount({
    queryClient, 
    devUser:devUser,
    onSuccess:()=>{
      navigate({to:'/home'})
    }
  })
  const { register:confirmationCodeRegister, handleSubmit:confirmSubmit  } = useForm()
  const {mutateAsync:confirmSignUp}=useConfirmSingup()
  const {mutateAsync:SignUp,isSuccess:signupSuccess,isPending:signUpLoading,status:signUpStatus}=useSignUpExperimental()

  if(createAccountErrs){
    toast.error("Unable to login due to an error!",{
      description: `${createAccountErrs}`,
      action: {
        label: "Dismiss",
        onClick: () => {},
      },
      style: {
        background: '#dc2626'
      }
    })
  }

  const submitRef = useRef(null) as any
  const _triggerSubmit = () => {
    submitRef?.current?.click(); // Programmatically click the hidden submit button
  };


  const onSubmit = async (data:any) => {
    try{
      await SignUp({
        name:data.userName,
        email:data.email,
        password:data.password,
        phone_number:'+254712345678',
      })
      if (signUpStatus === 'success'){
        setConfirmationStage();
      }
    }catch(err){
      toast.error("Unable to submit!",{
        description: `${(err as AxiosError).message}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
    }
  };

  
  const resendConfirmationCodeFunc=()=>{
    const userName=getValues('userName')
    try{
      resendConfirmationCode({userName:userName})
      toast.success("Code resent!",{
        description: "Confirmation code resent successfully",
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#a3e635'
        }
      })
    }catch(e){
      toast('Try again later', {
        description: e as string,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
      });
    }
    
  }

  const confirmEmail = async (data:any) => {
    const userName=getValues('userName');
    const password=getValues('password')
    setSignupCodeStatus('in progress')
    try{
      await confirmSignUp({
          code:data.confirmationCode,
          userName,
          password
        })
      setSignupCodeStatus('idle')
      await createAccount()
    }catch(e){
      toast.error("Unable to sign up due to an error!",{
        description: `${e}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
      throw ({confirmEmailErr:e})
    }
    

  }

  if(signupSuccess){
    return (
      <>
        <CardWithNoFooter 
        cardTitle="Confirm e-mail"
        cardDescription="Enter the confirmation code sent to your email"
        classname="bg-white">
          <form onSubmit={confirmSubmit(confirmEmail)} className="flex flex-col space-y-1.5 bg-white">
            <Input  id={'confirmEmail'} type={'text'} {...confirmationCodeRegister('confirmationCode')} name={'confirmationCode'}/>
            <div className="min-h-40">
            {loading && <LinearProgress/> }
            </div>
            <Button type='submit'>Confirm</Button>
          </form>
          <Button
          variant={'link'}
          onClick={resendConfirmationCodeFunc}>
            Resend code
          </Button>
        </CardWithNoFooter> 
        <Toaster/>
      </>
      
    )
  }


  return (
    <AuthCard 
    loading={signUpLoading}
    cardTitle="Create an account"
    cardDescription="Enter your email and password to create an account"
    buttonText="Continue"
    onSubmit={_triggerSubmit}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormElements
        errors={errors}
        formMembers={[
          {id:"userName",label:"Username",type:"text",register,name:"userName"},
          {id:"email",label:"Email",type:"email",register,name:"email"},
          {id:"password",label:"Password",type:"password",register,name:"password"},
          {id:"confirmPassword",label:"Confirm Password",type:"password",register, name:"confirmPassword"}
      ]}/>
        <input type="submit" value="Submit" style={{ display: 'none' }} ref={submitRef} />
      </form>
    </AuthCard>
  )


}

