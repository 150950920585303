
const TestDoneText = () => {
    //Q&A or Algo
    return (
        <>
            <h1 className="text-2xl font-bold">Tests for this skill are already completed!</h1>
            
        </>
    )
}
  
  export default TestDoneText