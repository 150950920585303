import { CardHeader, CardTitle, CardDescription, Card, CardContent } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select"
import { Textarea } from "@/components/ui/textarea"
import { Button } from "@/components/ui/button"
import { useForm } from "react-hook-form"
import { Form, FormControl, FormField, FormItem } from "@/components/ui/form"
import { useNavigate } from "@tanstack/react-router"
import { toast } from "sonner"
import { Application, PostApplyBody } from "../../../openapi/api/model"
import { usePostApplication } from "./utils/hooks"


const ApplicationForm = ({hiringCampaignId}:{hiringCampaignId?:string}) => {

  const navigate = useNavigate({from: '/'})
  const {mutate:application} = usePostApplication({
    onSuccess:()=>{
      navigate({to: '/apply/$hiringcampaignId/submitted'})
    },
    onError: (error)=>{
      toast.error("Failed to submit application!",{
        description: `${error.message}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
    }
  })
  
  const form = useForm({})

  const onSubmit = async(data:any) => {
    //const data = data as ApplicationFormValues
      const formData = new FormData()
      formData.append('formData',JSON.stringify({
        firstName: data.firstName??'', 
        middleName: data.middleName??'', 
        lastName: data.lastName??'',
        streetAddress: data.streetAddress??'', 
        email: data.email??'', 
        phoneNumber: data.phoneNumber??'', 
        city: data.city??'', 
        state: data.state??'',
        zipCode: data.zipCode??'', 
        dateOfBirth: data.dateOfBirth??'', 
        positionAppliedFor: data.positionAppliedFor??'', 
        desiredSalary: data.desiredSalary??'', 
        availableStartDate: data.availableStartDate??'', 
        employmentType: data.employmentType??'', 
        howDidYouHear: data.howDidYouHear??'', 
        currentEmployer: data.currentEmployer??'', 
        jobTitle: data.jobTitle??'', 
        datesOfEmployment: {
          startDate: data.datesOfEmployment?.startDate??'', 
          endDate: data.datesOfEmployment?.endDate??'' 
        },
        responsibilities: data.responsibilities??'', 
        reasonForLeaving: data.reasonForLeaving??'', 
        educationLevel: data.educationLevel??'', 
        universityName: data.universityName??'', 
        aboutYou: data.aboutYou??'', 
        hiringCampaignId: hiringCampaignId??''
      } as Application))

      // Append file
      if (data.resume && data.resume[0]) {
        formData.append('resume',data.resume[0]);
      }

      //mutate({data:formData as PostApplyBody})
      application({formData:formData as PostApplyBody})
  }

  return (
    <div className="w-full min-h-screen flex flex-col">
      <header className="bg-red-700 text-primary-foreground py-8 px-4 sm:px-8">
        <div className="container max-w-2xl mx-auto">
          <CardHeader>
            <CardTitle className="text-2xl">Job Application</CardTitle>
            <CardDescription>Fill out the form below to apply for the position.</CardDescription>
          </CardHeader>
        </div>
      </header>
      <div className="flex-1 py-8 px-4 sm:px-8">
        <Card className="w-full max-w-2xl mx-auto">
          <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-4 py-8">
              <div className="grid grid-cols-3 gap-4">
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="firstName">First Name</Label>
                        <FormControl>
                          <Input id="firstName" placeholder="John" {...form.register("firstName")} className={`${
                          form.formState.errors["firstName"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
                <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="middleName"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="middleName">Middle Name</Label>
                      <FormControl>
                        <Input id="middleName" placeholder="Q." {...form.register("middleName")}/>
                      </FormControl>
                    </FormItem> 
                  )}
                  />
                </div>
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="lastName">Last Name</Label>
                        <FormControl>
                          <Input id="lastName" placeholder="Doe" {...form.register("lastName")} className={`${
                          form.formState.errors["lastName"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4">
                <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="streetAddress"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="streetAddress">Street Address</Label>
                      <FormControl>
                        <Input id="streetAddress" placeholder="123 Main St" {...form.register("streetAddress")} className={`${
                          form.formState.errors["streetAddress"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                      </FormControl>
                    </FormItem> 
                  )}
                  />
                </div>
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="city"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="city">City</Label>
                        <FormControl>
                          <Input id="city" placeholder="San Francisco" {...form.register("city")} className={`${
                          form.formState.errors["city"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="state"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="state">State</Label>
                        <FormControl>
                          <Input id="state" placeholder="CA" {...form.register("state")} className={`${
                          form.formState.errors["state"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="zipCode"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="zipCode">Zip Code</Label>
                        <FormControl>
                          <Input id="zipCode" placeholder="94101" {...form.register("zipCode")} className={`${
                          form.formState.errors["zipCode"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="phoneNumber">Phone Number</Label>
                        <FormControl>
                          <Input id="phoneNumber" type="tel" placeholder="(123) 456-7890" {...form.register("phoneNumber")} className={`${
                          form.formState.errors["phoneNumber"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="email"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="email">Email Address</Label>
                        <FormControl>
                          <Input id="email" type="email" placeholder="john@example.com" {...form.register("email")} className={`${
                          form.formState.errors["email"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="dateOfBirth"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="dateOfBirth">Date of Birth</Label>
                      <FormControl>
                        <Input id="dateOfBirth" type="date" {...form.register("dateOfBirth")}/>
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="positionAppliedFor"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="positionAppliedFor">Position Applied For</Label>
                        <FormControl>
                          <Input id="positionAppliedFor" placeholder="Software Engineer" {...form.register("positionAppliedFor")} className={`${
                          form.formState.errors["positionAppliedFor"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <FormField
                  control={form.control}
                  name="desiredSalary"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="desiredSalary">Desired Salary</Label>
                      <FormControl>
                        <Input id="desiredSalary" type="number" placeholder="$80,000" {...form.register("desiredSalary")} className={`${
                          form.formState.errors["desiredSalary"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                      </FormControl>
                    </FormItem> 
                  )}
                  />
                </div>
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="availableStartDate"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="availableStartDate">Available Start Date</Label>
                      <FormControl>
                        <Input id="availableStartDate" type="date" {...form.register("availableStartDate")} className={`${
                          form.formState.errors["availableStartDate"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="employmentType">Employment Type</Label>
                <FormField
                  control={form.control}
                  name="employmentType"
                  render={({field}) => (
                    <FormItem>
                      <Select onValueChange={field.onChange} defaultValue={field.value} required>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select employment type" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="full-time">Full-time</SelectItem>
                          <SelectItem value="part-time">Part-time</SelectItem>
                          <SelectItem value="temporary">Temporary</SelectItem>
                          <SelectItem value="contract">Contract</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="howDidYouHear"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="howDidYouHear">How Did You Hear About This Job?</Label>
                      <FormControl>
                        <Input id="howDidYouHear" placeholder="Referral, job board, etc." {...form.register("howDidYouHear")}/>
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="currentEmployer"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="currentEmployer">Current/Most Recent Employer</Label>
                      <FormControl>
                        <Input id="currentEmployer" placeholder="Acme Inc." {...form.register("currentEmployer")} className={`${
                          form.formState.errors["currentEmployer"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="jobTitle"
                    render={()=>(
                      <FormItem>
                        <Label htmlFor="jobTitle">Job Title</Label>
                        <FormControl>
                          <Input id="jobTitle" placeholder="Software Engineer" {...form.register("jobTitle")} className={`${
                          form.formState.errors["jobTitle"]
                            ? "border-2 border-red-500"
                            : "border-primary100"
                        }`} required/>
                        </FormControl>
                      </FormItem> 
                    )}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="current-employment-dates">Dates of Employment</Label>
                  <div className="grid grid-cols-2 gap-2">
                  <FormField
                    control={form.control}
                    name="datesOfEmployment.startDate"
                    render={()=>(
                      <FormItem>
                        <FormControl>
                          <Input id="startDate" type="date" {...form.register("datesOfEmployment.startDate")}/>
                        </FormControl>
                      </FormItem> 
                      )}
                    />
                    <FormField
                    control={form.control}
                    name="datesOfEmployment.endDate"
                    render={()=>(
                      <FormItem>
                        <FormControl>
                          <Input id="endDate" type="date" {...form.register("datesOfEmployment.endDate")}/>
                        </FormControl>
                      </FormItem> 
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="responsibilities"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="responsibilities">Responsibilities/Duties</Label>
                      <FormControl>
                        <Textarea
                          id="responsibilities"
                          placeholder="Describe your responsibilities..."
                          className="min-h-[150px]"
                          {...form.register("responsibilities")}
                        />
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="reasonForLeaving"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="reasonForLeaving">Reason for Leaving</Label>
                      <FormControl>
                        <Textarea
                          id="reasonForLeaving"
                          placeholder="Explain your reason for leaving..."
                          className="min-h-[150px]"
                          {...form.register("reasonForLeaving")}
                        />
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <div className="space-y-2">
              <FormField
                  control={form.control}
                  name="educationLevel"
                  render={({field}) => (
                    <FormItem>
                      <Select onValueChange={field.onChange} defaultValue={field.value} required>
                        <FormControl>
                          <SelectTrigger className={`${
                            form.formState.errors["educationLevel"]
                              ? "border-2 border-red-500"
                              : "border-primary100"
                            }`}>
                            <SelectValue placeholder="Select highest level of education" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="high-schoo">High School</SelectItem>
                          <SelectItem value="associate">Associate's</SelectItem>
                          <SelectItem value="bachelor">Bachelor's</SelectItem>
                          <SelectItem value="master">Master's Degree</SelectItem>
                          <SelectItem value="doctorate">Doctorate</SelectItem>
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
              </div>
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="universityName"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="universityName">University Name</Label>
                      <FormControl>
                        <Input id="universityName" placeholder="University Name" {...form.register("universityName")} className={`${
                        form.formState.errors["universityName"]
                          ? "border-2 border-red-500"
                          : "border-primary100"
                        }`} required/>
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div> 
              <div className="space-y-2">
                <FormField
                  control={form.control}
                  name="aboutYou"
                  render={()=>(
                    <FormItem>
                      <Label htmlFor="aboutYou">About You</Label>
                      <FormControl>
                        <Textarea
                          id="aboutYou"
                          placeholder="Tell us about yourself..."
                          className="min-h-[150px]"
                          {...form.register("aboutYou")}
                        />
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="resume">Resume</Label>
                <FormField
                  control={form.control}
                  name="resume"
                  render={()=>(
                    <FormItem>
                      <FormControl>
                        <Input id="resume" type="file" accept=".docx,.pdf" {...form.register("resume")} className={`${
                        form.formState.errors["resume"]
                          ? "border-2 border-red-500"
                          : "border-primary100"
                        }`} required/>
                      </FormControl>
                    </FormItem> 
                  )}
                />
              </div>
              <Button type="submit" className="ml-auto">
                Submit Application
              </Button>
            </form>
            </Form>
          </CardContent>
        </Card>
      </div>
      
    </div>
  )
}

export default ApplicationForm;