import { PieChartDisplayerProp } from "@/components/ui/Pie"
import { ProjectResultsMacro } from "openapi/api/model/projectResultsMacro";


export const data01: PieChartDisplayerProp = {metricDataName: "Data1", metricData: [
        { name: 'Group A', total: 400 },
        { name: 'Group B', total: 300 },
        { name: 'Group C', total: 300 },
        { name: 'Group D', total: 200 },
      ]
    }

export const data02: PieChartDisplayerProp = {metricDataName: "Data2", metricData: [
        { name: 'A1', total: 100 },
        { name: 'A2', total: 200 },
        { name: 'A2', total: 200 },
        { name: 'B1', total: 100 },
        { name: 'B2', total: 80 },
      ]
    }

  export const data1 = [
      { label: 'Front-End', value: 95, color: '#0088FE' },
      { label: 'Back-End', value: 88, color: '#00C49F' },
      { label: 'Version Control', value: 81, color: '#FFBB28' },
      { label: 'Coding Practices', value: 75, color: '#FF8042' },
  ];

  export const data2 = [
    { label: 'Commits', value: 7, color: '#0088FE' },
    { label: 'Pull requests', value: 3, color: '#00C49F' },
];

export const data3 = [
  { label: 'Back-End', value: 98, color: '#00C49F' },
  { label: 'Version Control', value: 81, color: '#FFBB28' },
  { label: 'Coding Practices', value: 75, color: '#FF8042' },
];

export const data4 = [
{ label: 'Commits', value: 11, color: '#0088FE' },
{ label: 'Pull requests', value: 3, color: '#00C49F' },
];
    

export const barChartData = [
    {name: "React", total: 70}, 
    {name: "JS", total: 100}, 
    {name: "TS", total: 97},
    {name: "CSS", total: 93},
  ]

export const barChartData2 = [
    {name: "MongoDB", total: 86}, 
    {name: "SQL", total: 94}, 
    {name: "NoSQL", total: 97},
    {name: "AWS", total: 93},
    {name: "NodeJS", total: 84}
  ]

  export const data03: PieChartDisplayerProp = {metricDataName: "Data3", metricData: [
    { name: 'Group A', total: 100 },
    { name: 'Group B', total: 400 },
    { name: 'Group C', total: 500 },
    { name: 'Group D', total: 250 },
  ]
}

export const data04: PieChartDisplayerProp = {metricDataName: "Data4", metricData: [
    { name: 'A1', total: 300 },
    { name: 'A2', total: 400 },
    { name: 'B1', total: 100 },
    { name: 'B2', total: 200 },
  ]
}

export const barChartData3 = [
  {name: "React", total: 90}, 
  {name: "JS", total: 80}, 
  {name: "TS", total: 92},
  {name: "CSS", total: 93},
]

export const barChartData4 = [
  {name: "MongoDB", total: 86}, 
  {name: "SQL", total: 74}, 
  {name: "NoSQL", total: 83},
  {name: "AWS", total: 95},
  {name: "NodeJS", total: 84}
]


export const performance = [
  {name:"Code Quality and Efficiency",description:"Reusability, Scalability"},
  {name:"Problem-Solving Skills",description:"Algorithms"},
  {name:"Project Management",description:"Organisation"},
  {name:"Communication Skills",description:"Comments"}
]

export const ovr: ProjectResultsMacro = {
  overallRatingData:{
    reliability: 0,
    security: 0,
    maintainability: 0
  },
  Code_Reliability:{
    blocker_violations: 0,
    critical_violations: 0,
    minor_violations: 0,
    major_violations: 0
  },
  Code_Maintainability:{
    code_smells: 0,
    technical_debt: 0,
    sqale_index: 0,
    duplicated_line_density: 0
  },
  Security_Compliance:{
    vulnerability:{
      CRITICAL:0,
      MAJOR:0,
      MINOR:0
    },
    hotspots:0
  }
}