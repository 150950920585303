/* eslint-disable @typescript-eslint/ban-ts-comment */
import Axios, { AxiosRequestConfig, AxiosError } from 'axios';




export const AXIOS_INSTANCE = Axios.create({ 
  withCredentials:true,// enabling the browser to include relevant cookies
  baseURL: import.meta.env.VITE_HOST_URL??'https://api.apisdevf.net/experimental/' }); 

// add a second `options` argument here if you want to pass extra options to each generated query
export const customClient = <T>(
    config: AxiosRequestConfig,
    options?: AxiosRequestConfig,
    ): Promise<T> => {
    const source = Axios.CancelToken.source();
    //const authPermissionsConfig=permissionsMiddleware({config:authConfig})
    const promise = AXIOS_INSTANCE({
        ...config,
        ...options,
        cancelToken: source.token,
    }).then(({ data }) => data)
    .catch((err)=>{
        if(config.url==='/auth/status'){
            return
        }
        throw err
    })
        

    // @ts-ignore
    promise.cancel = () => {
        source.cancel('Query was cancelled');
    };

    return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

export type BodyType<BodyData> = BodyData;
 