import { useEffect, useState } from "react";

const useBlinker=()=>{
    const [blinkCursor, setBlinkCursor] = useState(true);

    useEffect(() => {
      const interval = setInterval(() => {
        setBlinkCursor((prev) => !prev);
      }, 530);
      return () => clearInterval(interval);
    }, []);
  
    return {blinkCursor}
}


export default useBlinker