"use client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { toast } from "sonner"
//import { useEffect } from 'react';
import { DevUser } from "openapi/api/model"
import { useGetUserDeveloper, usePutUserDeveloper } from '../../../../openapi/api/endpoints/default/default';
import { ProfileFormValues, profileFormSchema } from './ProfileFormSchema';
import { queryClient } from "@/App"


export function ProfileForm() {
  const { data: user } = useGetUserDeveloper()

  const {mutate}=usePutUserDeveloper({
    mutation: {
      onSuccess: () => {
        toast.success("Updated!", {
          description: "Profile updated successfully",
          action: {
            label: "Dismiss",
            onClick: () => {},
          },
          style: {
            background: "#a3e635",
          },
        });
      },
      onSettled: ()=>{
        queryClient.invalidateQueries({queryKey: [`/user-developer`]})
      },
      onError:(error)=>{
        toast.error("Failed to update profile!",{
            action: {
              label: "Dismiss",
              onClick: () => {},
            },
            style: {
              background: '#dc2626'
            }
          })
        throw({error})
      }
    }
  });

  const form = useForm<ProfileFormValues>({
    defaultValues: {
      email:user?.email??'',
      bio: user?.bio??'',
      github_ID:user?.github_ID??'',
      userName:user?.userName??''
    },
    resolver: zodResolver(profileFormSchema)
  })

  const onSubmit=(data: ProfileFormValues)=>{
    const profile:DevUser={
      email:user?.email??'',
      bio: data.bio??'',
      github_ID:user?.github_ID??'',
      sub:user?.sub??'',
      userName:user?.userName??''
    }
    mutate({data:profile})
  }

    
    /* useEffect(() => {
      if (isGetUserSuccess && user) {//only rerender if these conditions are satisfied
        form.reset(user);
      }
    }, [form, user, isGetUserSuccess]); // Only re-run if these dependencies change    
     */
   /*
    if (isGetUserSuccess && databcknd) {//wont work as the body will be evaluated on every rerender, and we trigger a rerender here
      //which reevaluates the expression, which causes another rerender -> an infinite loop
      form.reset(databcknd);
    }   
   */

  


  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="userName"
          render={() => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input placeholder="username"
                {...form.register('userName')}
                readOnly={true}
                className="bg-mint100"/>
              </FormControl>
              <FormDescription>
               
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={() => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="email"
                {...form.register('email')}
                readOnly={true}
                className="bg-mint100"/>
              </FormControl>
              <FormDescription>
                
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="bio"
          render={() => (
            <FormItem>
              <FormLabel>Bio</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Tell us a little bit about yourself"
                  className="resize-none"
                  {...form.register('bio')}
                />
              </FormControl>
              <FormDescription>
                
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="github_ID"
          render={() => (
            <FormItem>
              <FormLabel>githubId</FormLabel>
              <FormControl>
                <Input placeholder="githubId"
                {...form.register('github_ID')}  
                readOnly={true}
                className="bg-mint100"/>
              </FormControl>
              <FormDescription>
                
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        
        <Button className="bg-teal100 hover:bg-blue100" type="submit">Update profile</Button>
      </form>
    </Form>
  )
}