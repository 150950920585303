import { Toaster } from "sonner"
import { MainAppRoute } from '@/rootRoutes/MainApp';
import { Route, useBlocker, useNavigate, useParams } from '@tanstack/react-router';
import { useContext, useEffect, useState } from 'react';
import ChatEvaluation from './Chat/Chat';
import { AuthContext } from "@/store/track-context";
import { ChatSocket } from "@/utils/Websocket/socket";
import LoadingComponent from "./LoadingComponent";

export const BeginQATestPage= ()=>{
  const {courseId,skill} = useParams({from:'/'});
  const navigate = useNavigate({from:'/'})
  const {track} = useContext(AuthContext)
  const [isBlockerActive, setIsBlockerActive] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false)
    
  const disableBlocker = () => {
    setIsBlockerActive(false);
  }

  useEffect(() => {
    if (!isBlockerActive) {
      // Navigate to the overview page after blocker is disabled
      navigate({to:'/overview'});
    }
  }, [isBlockerActive, navigate]);

  useBlocker({
    blockerFn:async () => {
      if (!isBlockerActive) return true;
      const confirmLeave=window.confirm('Are you sure you want to leave? This would terminate the evaluation')
      if(confirmLeave){
        setIsSubmitting(true)
        // Simulating an API call
        await new Promise(resolve => setTimeout(resolve, 3000))
        setIsSubmitting(false)
      }
      return confirmLeave
    },
    condition:isBlockerActive
  })

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // show a confirmation dialog when attempting to close browser
      const message = "Are you sure you want to leave?";
      event.returnValue = message; // Legacy way of showing a confirmation dialog
      return message; // Modern way of showing a confirmation dialog
    };

    const handleUnload = () => {
      ChatSocket.disconnect()
      console.log('Leaving page...')
    };
    //attach event listeners on mount
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);


    return () => {
      //get rid of event listeners on dismount
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    }
  
  }, [])

  return(
  <>
    <div className="p-4">
    <LoadingComponent 
      isSubmitting={isSubmitting}  
      loadingTitle="Submitting Assignment"
      loadingMessage="Please wait while we process your submission. This may take a few moments."/>
    </div>
    <ChatEvaluation sub={track?.sub??''} projectId={courseId} trackId={track?._id??''} hiringCampaignId={track?.hiringCampaignId??''} skill={skill} blockerFn={disableBlocker}/>
    <Toaster/>
  </>
  )
}


const BeginQATestRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "course/$courseId/$skill/QA/$templateId",
  component: BeginQATestPage,
})

export default BeginQATestRoute;