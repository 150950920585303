import { Clock } from 'lucide-react';
import {  useEffect, useState } from 'react'



const Timer = ({timerHours,timerMinutes, timerDone}:{timerHours?:number,timerMinutes?:number, timerDone?: () => void}) => {
    
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const currentDate = new Date()
  const deadlineTime = new Date(currentDate.getTime() + ((timerHours??0) * 60 + (timerMinutes??0)) * 60 * 1000).toISOString()
  //console.log(deadlineTime)

  const formatTime = (hrs: number, mins: number, secs: number) => {
    const formattedHours = hrs
    const formattedMinutes = mins
    const formattedSecs = secs
    return `${formattedHours.toString().padStart(2, '0')}:${formattedMinutes
      .toString()
      .padStart(2, '0')}:${formattedSecs.toString().padStart(2, '0')}`
  }

  const getTime = (deadline:string) => {
      const time = Date.parse(deadline) - Date.now();    

      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
      const interval = setInterval(() => getTime(deadlineTime), 1000);

      return () => clearInterval(interval);
  }, []);
  
  if(hours<0 && minutes<0 && seconds<0 ){
    timerDone&&timerDone();
    console.log('doneee')
  }
  
  return (
    <div className="flex items-center space-x-3 bg-[#101820] px-4 py-2 rounded-md">
      <Clock className="h-6 w-6 text-[#26A69A]" />
      <span className="text-2xl font-mono text-[#ccfbf1]">{formatTime(hours,minutes,seconds)}</span>
    </div>
  )
}

export default Timer