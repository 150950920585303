import { useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

export const usePostRuntest=({onSuccess, onError}:{onSuccess:(data:any)=>void, onError?:(error:AxiosError)=>void})=>{
   
    return useMutation({
        mutationFn:postRuntest,
        onSuccess: (data:any)=>{
            onSuccess&&onSuccess(data);
        },
        onError: (error)=>{
            onError&&onError(error as AxiosError)
        }
    })
}

const postRuntest = async({data}:{data:any}) => {
    try{
        const response = await axios.post('https://api.apisdevf.net/runtest/', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    }catch(error){
        throw new Error(`${(error as AxiosError).message}`);
    }
}