import { Card } from "../ui/card";
import Timer from "../ui/custom/Timer";

const MarkdownEditor = ({markdown,timerDone}:{markdown?:string,timerDone?:()=>void}) => {
    
    const renderMarkdown = (text?:string) => {
        const lines = text?.split('\n');
        return lines?.map((line, index) => {
            // Headers
            if (line.startsWith('# ')) {
                return <h1 key={index} className="text-lg font-bold">{line.slice(2)}</h1>;
            } else if (line.startsWith('## ')) {
                return <h2 key={index} className="text-xl font-bold">{line.slice(3)}</h2>;
            } else if (line.startsWith('### ')) {
                return <h3 key={index} className="text-2xl font-bold">{line.slice(4)}</h3>;
            }
            // Bold
            else if (line.includes('**')) {
                const parts = line?.split(/\*\*(.+?)\*\*/);
                return (
                    <p key={index}>
                        {parts.map((part, idx) =>
                            idx % 2 === 1 ? <strong key={idx}>{part}</strong> : part
                        )}
                    </p>
                );
            }
            // Italics
            else if (line.includes('*')) {
                const parts = line?.split(/\*(.+?)\*/);
                return (
                    <p key={index}>
                        {parts.map((part, idx) =>
                            idx % 2 === 1 ? <em key={idx}>{part}</em> : part
                        )}
                    </p>
                );
            }
            // Lists
            else if (line.startsWith('- ')) {
                return <ul key={index}><li>{line.slice(2)}</li></ul>;
            }
            // Regular paragraph
            return <p key={index}>{line}</p>;
        });
    };


    return (
        
            <Card className="border rounded-lg p-2 bg-white flex-auto w-full h-full flex flex-col space-y-4 overflow-y-auto">
                <div className="flex justify-center">
                    <Timer timerMinutes={45} timerDone={timerDone}/>
                </div>
                <div>
                    {renderMarkdown(markdown)}
                </div>
                
            </Card>
        
    );
};

export default MarkdownEditor;
