
//import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, CartesianGrid } from "recharts"
//import { ResponsiveChartContainer } from '@mui/x-charts';
import { BarChart } from '@mui/x-charts/BarChart';
import { ResponsiveContainer } from 'recharts';

export type MetricData={
  //refactor this
    name:string,
    total:number
}

export type BasicChartProps = {
  chart:any,
  labels?:string[],
}

export const BarChartDisplayer = ({chart,labels}:BasicChartProps) => {
//const labelArr = ["Code Smells", "Technical Debt", "Squale Index", "Duplicated Line"]
//const securityLabelArr = ["Vulnerability", "Hotspots"]

const keyVal_to_array =(obj:any)=>{
  return Object.keys(obj).map((key)=>{
      return obj[key]
      
      
    }
  )
}
const barData = keyVal_to_array(chart)

const h=250
  const w=110
  const uData = barData??[0]

  const xLabels = labels??[""] 

//const valueFormatter = (value: number) => `${value}%`;
/* <ResponsiveContainer width="100%" height={250}>
      <BarChart data={barData} margin={{ top: 20, right: 5, bottom: -10, left: -25 }}>
      <CartesianGrid strokeDasharray="4" />
        <XAxis
          dataKey="name"
          stroke="black"
          fontSize={12}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          stroke="black"
          fontSize={12}
          tickLine={false}
          axisLine={false}
          tickFormatter={(value) => `${value}`}
        />
        <Bar dataKey="total" fill="#02f7ef" radius={[4, 4, 0, 0]} />
      </BarChart>
    </ResponsiveContainer> */
  return (
    <ResponsiveContainer width={`${w}%`} height={h}>
      <BarChart
      //dataset={barData}
      xAxis={[{ 
        scaleType: 'band', 
        data: xLabels,
        
      }]}
      series={[
        { data: uData, },
      ]}
      width={300}
      height={200}
      //{...chartSetting}
    />
    </ResponsiveContainer>
      
    
    
  )
}
