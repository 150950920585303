/* eslint-disable @typescript-eslint/no-explicit-any */
import { MainAppRoute } from "@/rootRoutes/MainApp";
import { Route, useParams } from '@tanstack/react-router';
import { useGetProjectsProjectId, useGetSebFile } from '../../../openapi/api/endpoints/default/default';
import { Button } from "@/components/ui/button";
import { ArrowBigDownDash } from "lucide-react";

const DownloadSEB = () => {
  const {courseId} = useParams({from:'/'});
  const {isLoading} = useGetProjectsProjectId(courseId)
  const {data:win10} = useGetSebFile({OSType:'win10'})
  const {data:win11} = useGetSebFile({OSType:'win11'})
  const {data:macos} = useGetSebFile({OSType:'macos'})
  
  if(isLoading){
    return(
      <div className="flex flex-col p-4 bg-gray-100 min-h-screen max-md:w-screen overflow-x-auto">
        ...Loading
      </div>
    )
  }

  const downloadWin10 = () => {
    const fileUrl = `${win10}`; 
    window.open(fileUrl, '_blank');
  }

  const downloadWin11 = () => {
    const fileUrl = `${win11}`; 
    window.open(fileUrl, '_blank');
  }

  const downloadMacOS = () => {
    const fileUrl = `${macos}`; 
    window.open(fileUrl, '_blank');
  }
  
  return (
    <div className="flex flex-col justify-center items-center p-4 bg-gray-100 min-h-screen space-y-8 max-md:w-screen overflow-x-auto">

      <div className="flex flex-col h-full justify-center items-center space-y-8">

        <div className="flex flex-col h-full items-center justify-center space-y-4">
          <h1 className="text-2xl font-bold">Launch The Safe Exam Browser</h1>
          <div className="flex flex-col items-center">
            <p className="flex flex-row">Our evaluation takes place in a<p className="font-bold">&nbsp;locked browser&nbsp;</p>environment where you can only</p>
            <p className="flex flex-row">access our workspace</p>
          </div>
          <div className="flex flex-col items-center">
            <p className="flex flex-row"><p className="font-bold">&nbsp;Do not close&nbsp;</p>the safe exam browser until your exam is complete.</p>
          </div>
        </div>

        <div className="border-b-2 border-black w-48"></div>

        <div className="flex flex-col h-full items-center justify-center space-y-4">
          <h1 className="text-xl font-bold">Steps to launch</h1>
          <div className="flex flex-col items-center">
            <p>1.</p>
            <p className="flex flex-row">Download the SEB installation package</p>
          </div>
          <div className="flex flex-col items-center">
            <p>2.</p>
            <p className="flex flex-row">Open the download and package.</p>
            <p className="flex flex-row">Installation instructions are included in the download files</p>
          </div>
          <div className="flex flex-col items-center">
            <p>3.</p> 
            <p className="flex flex-row items-center">Once the SEB has launched you will be asked to log back in and click</p> 
            <p className="flex flex-row">"open workspace"</p>
          </div>
          <div className="grid grid-cols-3 space-x-2 py-16">
            <Button className="rounded-full bg-teal100 hover:bg-blue100 px-8" onClick={downloadWin10}><ArrowBigDownDash/>Windows 10</Button>
            <Button className="rounded-full bg-teal100 hover:bg-blue100 px-8" onClick={downloadWin11}><ArrowBigDownDash/>Windows 11</Button>
            <Button className="rounded-full bg-teal100 hover:bg-blue100 px-8" onClick={downloadMacOS}><ArrowBigDownDash/>Mac OS</Button>
          </div>
        </div>

      </div>

    </div>
    
  )
}

const DownloadSEBRoute = new Route({
    getParentRoute: () => MainAppRoute,
    path: "course/$courseId/downloadSEB",
    component: DownloadSEB,
    stringifyParams: ({ courseId }) => ({ courseId: `${courseId}` }),
})

export default DownloadSEBRoute;

