import { Toaster } from "sonner"
import { MainAppRoute } from '@/rootRoutes/MainApp';
import { Route } from '@tanstack/react-router';


export const TestPage= ()=>{
    
  return(
  <>
    <Toaster/>
  </>
  )
}


const TestRoute = new Route({
  getParentRoute: () => MainAppRoute,
  path: "test",
  component: TestPage,
})

export default TestRoute;