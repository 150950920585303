import * as React from "react"
import {  CheckIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button"
import { TitleSmall } from "../Title";

type DropDownSelectProps={
    data:{
        itemKey:string,
        itemValue:string,
    }[],
    dropDownMenuLabel?:string,
    onSelect:({itemID}:{itemID:string})=>void,
    initialValue:string


}

const DropDownSelect=({data,dropDownMenuLabel,onSelect,initialValue}:DropDownSelectProps)=>{
  const [value, setValue] = React.useState<string>("")
  const [openSelector, setOpenSelector] = React.useState(false)

  const handleSelect=(currentValue:string)=>{
    const lowerCaseValues=data.map((item)=>{
      return {
        ...item,
        itemValue:item.itemValue.toLowerCase()
      }
    })
    const trueItemKey=lowerCaseValues.find((item)=>item.itemValue===currentValue)?.itemKey??''
    setValue(trueItemKey === value ? "" : trueItemKey)
    setOpenSelector(false)
    onSelect({itemID:trueItemKey})
  }
  

  return (
    <Popover open={openSelector} onOpenChange={setOpenSelector}>
    <PopoverTrigger asChild>
      <Button
        variant="outline"
        role="combobox"
        aria-expanded={openSelector}
        className="w-[400px] justify-between text-black bg-white"
      >
        <div>
          {value && data.find((proj) => proj.itemKey === value)?.itemValue}
          {!value && `${initialValue}...`}
        </div>
        <MagnifyingGlassIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
    </PopoverTrigger>
    <PopoverContent className="w-[400px] p-0 bg-white">
      <Command>
        <CommandInput placeholder={dropDownMenuLabel} className="h-9" />
        <CommandEmpty>No item found.</CommandEmpty>
        <CommandGroup>
          {data.map((item) => (
            <CommandItem
              key={item.itemKey}
              value={item.itemValue}
              onSelect={handleSelect}
            > 
            <TitleSmall>
              {item.itemValue}
            </TitleSmall>
              
              <CheckIcon
                className={cn(
                  "ml-auto h-4 w-4",
                  value === item.itemKey ? "opacity-100" : "opacity-0"
                )}
              />
            </CommandItem>
          ))}
        </CommandGroup>
      </Command>
    </PopoverContent>
  </Popover>
  )
}


export default DropDownSelect