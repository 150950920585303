import { RouterProvider } from '@tanstack/react-router'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { queryClient, router } from './App';
import '../app/globals.css'
import './index.css'
import {QueryClientProvider } from '@tanstack/react-query';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://9ada62616c5dd31ef64b0e2a0e5c22ea@o4507868338323456.ingest.us.sentry.io/4507868369584128",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/, /^https:\/\/dev\.experimental\.apisdevf\.net\/?/, /^https:\/\/app\.thedevfactory\.ca\/?/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
)
