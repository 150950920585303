import { Header } from "@/components/ui/Title";
import { TrackSelect } from "./TrackSelect";
import { BellIcon, EnvelopeClosedIcon } from "@radix-ui/react-icons";
import AvatarComponent from "./Avatar";
import AddCampaign from './AddCampaignPopover';

const HeaderComponent = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-row justify-between items-end mb-4">
      <div className="flex-grow text-left px-4 w-1/6">
        <Header>{title}</Header>
      </div>
      <div className="flex flex-grow ">
        <div className="flex px-2">
          <TrackSelect />
        </div>

        <div className="flex px-2 items-center">
          <AddCampaign />
        </div>
      </div>

      <div className="flex justify-center space-x-4 items-center px-4">
        <BellIcon width="19" height="23" className="mx-2" />{" "}
        {/* mx-2 for some horizontal margin */}
        <EnvelopeClosedIcon width="20" height="23" className="mx-2" />
        <AvatarComponent />
      </div>
    </div>
  );
};

export default HeaderComponent;
