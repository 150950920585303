/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthCard } from "@/components/Auth/AuthCard"
import FormElements from "@/components/ui/FormElements"
import { LoginSchema } from "@/utils/FormUtils/FormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useGetUserDeveloper } from "../../../../openapi/api/endpoints/default/default";
import { toast } from "sonner";
import { useLoginExperimental } from "../AuthUtils/authHooks";

export const Login = () => {
  const navigate=useNavigate({from:'/auth'})
  const { register, handleSubmit, formState: {errors} } = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      userName: "",
      password: "",
    }});
  
  const {refetch:getUser,isFetching}=useGetUserDeveloper({
    query:{
      enabled:false,
      refetchOnWindowFocus:false,
      retry:false
    }
  })

  

  const {mutateAsync:SignIn,isPending}=useLoginExperimental({onSuccess:()=>{
    navigate({to:'/home'})
  }})
  const _triggerSubmit = () => {
    submitRef?.current?.click();
  };
  const submitRef = useRef(null) as any

  const onSubmit = async (data:{userName:string,password:string}) => {
    try{
      await SignIn({
        userName:data.userName,
        password:data.password
      })
      const user = await getUser()
      if(user.status=='error'){
        throw user.error.response?.data
      } 
    }catch(err){
      toast.error("Unable to login due to an error!",{
        description: `${err}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
    }

  };

  
  return (
    <AuthCard 
    loading={isFetching || isPending}
    cardTitle="Log in"
    cardDescription="Enter your username and password to log into your account"
    buttonText="Log-in"
    onSubmit={_triggerSubmit}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormElements
        errors={errors}
        formMembers={[
          {id:"userName",label:"Username",type:"text",register,name:"userName"},
          {id:"password",label:"Password",type:"password",register,name:"password"}
      ]}/>
        <input type="submit" value="Submit" style={{ display: 'none' }} ref={submitRef} />
      </form>
    </AuthCard>
  )
}
