import { Route, useParams } from '@tanstack/react-router';
import ApplicationForm from './ApplicationForm';
import { rootRoute } from '@/App';
import { Toaster } from 'sonner';


export const Application = () => {
  const { hiringcampaignId } = useParams({from: ""})
  
  return (
    <div>
      <ApplicationForm hiringCampaignId={hiringcampaignId??''}/>
      <Toaster/>
    </div>
  )
}
// /apply/66a7f708500366a71343579d
const ApplicationRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "/apply/$hiringcampaignId",
    component: Application,
});

export default ApplicationRoute