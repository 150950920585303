import { HiringResultResultsMacro } from "openapi/api/model";
import { ProjectResultsMacro } from "openapi/api/model/projectResultsMacro";

type NewSecurityCompliance= {
    hotspots: number,
    vulnerability: number
}
const ChangeVulnerability = (chartData:ProjectResultsMacro|HiringResultResultsMacro) :NewSecurityCompliance =>{
    let vulnerabilityAvg =0
    let hotspotsAvg = 0
    if (chartData){
        vulnerabilityAvg = ((chartData.Security_Compliance?.vulnerability?.CRITICAL??0) +
                             (chartData.Security_Compliance?.vulnerability?.MAJOR??0) +
                             (chartData.Security_Compliance?.vulnerability?.MINOR ??0)) /3;
        hotspotsAvg = chartData.Security_Compliance?.hotspots ??0                   
                            }
    const newSecurity_Compliance: NewSecurityCompliance ={
        hotspots: hotspotsAvg,
        vulnerability: vulnerabilityAvg,
    }
    return newSecurity_Compliance
}
export default ChangeVulnerability