import { useState } from "react"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { HelpCircle } from "lucide-react"
import image from "../../../../assets/image.png"

export const WorkspaceInfo=()=>{

  const [isOpen, setIsOpen] = useState(true)
  
  return (
    <div className="self-end p-4">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" size="icon" className="rounded-full bg-gray-800 border-gray-700 hover:bg-gray-700 absolute top-0 right-0 text-[#26A69A] cursor-pointer">
            <HelpCircle className="h-6 w-6 text-white" />
            <span className="sr-only">About the workspace</span>
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[800px] w-11/12 max-h-[85vh] overflow-y-auto p-6 bg-gray-900 text-white border-gray-800">
          <DialogHeader>
            <DialogTitle className="text-3xl font-bold mb-6 text-center text-teal-500">About the Workspace</DialogTitle>
          </DialogHeader>
          <div className="space-y-10">
            <section className="bg-gray-800 rounded-lg p-6 shadow-sm">
              <h3 className="text-2xl font-semibold mb-4 text-teal-400">A- Navigating the Safe Exam Browser</h3>
              <p className="mb-4 text-gray-300 leading-relaxed">
                Right click on the logo button at the bottom left of your screen to select which tab you wish to go to. You can also have tabs side by side by dragging them.
              </p>
              <div className="flex justify-center items-center">
                <div className="flex relative h-56 w-72 bg-gray-700 rounded-md overflow-hidden shadow-md justify-center items-center">
                  <img
                    src={image}
                    alt="Illustrative image of Safe Exam Browser navigation"
                    width={224}
                    height={448}
                    className="rounded-md shadow-md"
                  />
                </div>
              </div>
            </section>
            <section className="bg-gray-800 rounded-lg p-6 shadow-sm">
              <h3 className="text-2xl font-semibold mb-4 text-teal-400">B- Starting the dev server</h3>
              <p className="mb-4 text-gray-300 leading-relaxed">To run setup/install dependencies and start dev server, paste these commands in the terminal:</p>
              <pre className="bg-gray-700 p-4 rounded-md overflow-x-auto shadow-inner">
                <code className="text-sm text-teal-300">chmod +x setup.sh && ./setup.sh</code>
              </pre>
            </section>
            <section className="bg-gray-800 rounded-lg p-6 shadow-sm">
              <h3 className="text-2xl font-semibold mb-6 text-teal-400">C- Working with the Dev Server</h3>
              <div className="space-y-6">
                <div className="bg-gray-700 p-4 rounded-md shadow-sm">
                  <h4 className="text-xl font-semibold mb-3 text-teal-300">Frontend evaluations:</h4>
                  <ul className="list-disc list-inside space-y-2 pl-4 mb-4 text-gray-300">
                    <li>For front end assignments, simply click on the <i>localhost</i> url once the workspace starts</li>
                    <li>The preview of your app should appear in the next tab</li>
                  </ul>
                  <div className="mt-4">
                    <img
                      src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2IaR6id8Y2mQ3KOwE8vxT7oNAQ7hEv.png"
                      alt="Vite server startup showing local and network URLs"
                      width={700}
                      height={280}
                      className="rounded-md shadow-md"
                    />
                  </div>
                </div>
                <div className="bg-gray-700 p-4 rounded-md shadow-sm">
                  <h4 className="text-xl font-semibold mb-3 text-teal-300">Backend evaluations:</h4>
                  <ul className="list-disc list-inside space-y-2 pl-4 mb-4 text-gray-300">
                    <li>We have provided you with hoppscotch.io, use it to test your backend application the same way you would use Postman or ThunderClient</li>
                    <li>Navigate to Ports (next to terminal). The application port should be auto-forwarded.</li>
                    <li>Copy that url (there is a button to copy it) and paste it in hoppscotch.io</li>
                  </ul>
                  <div className="mt-4">
                    <img
                      src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-ftWEhhuhlcBHNMg5h6fmGGN702CJZi.png"
                      alt="Port forwarding information showing the PORTS tab and copy button"
                      width={700}
                      height={280}
                      className="rounded-md shadow-md"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="bg-gray-800 rounded-lg p-6 shadow-sm">
              <h3 className="text-2xl font-semibold mb-4 text-teal-400">D- Submitting your work</h3>
              <ul className="list-disc list-inside space-y-2 pl-4 text-gray-300">
                <li>Simply push your code. Any userName and email will do and remote branch has already been setup</li>
                <li>Our system will pull your code from this branch and analyze your code</li>
              </ul>
            </section>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}