import {
    Avatar,
    AvatarFallback,
    AvatarImage,
  } from "@/components/ui/avatar"
import { Link } from "@tanstack/react-router"

const AvatarComponent = () => {

  return (
    <Link to={'/profile'} from={'/'}>
        <Avatar>
            <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
            <AvatarFallback>CN</AvatarFallback>
        </Avatar>
    </Link>

  )
}

export default AvatarComponent