/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { CardWithNoFooter } from "@/components/ui/CardWithNoFooter";
import { useQueryClient } from '@tanstack/react-query';
import { LinearProgress } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
//import { usePostUserDeveloper } from 'openapi/api/endpoints/default/default';
//import { AuthenticateUserResp } from '../AuthUtils/Login';
import { DevUser } from 'openapi/api/model';
import { toast } from 'sonner';
import { Toaster } from '@/components/ui/sonner';
import { resendConfirmationCode, useConfirmSingup } from '../AuthUtils/authHooks';
import { useCreateDev } from '@/utils/Auth/Session';
import { ObjectType } from '../AuthUtils/authTypes';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
//import { CognitoUser } from 'amazon-cognito-identity-js';

export interface CustomAxiosError<T = any> extends Error {
  config: AxiosRequestConfig;
  code?: string;
  request?: any; // The request object, often an XMLHttpRequest or http.ClientRequest
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
}

export const ResumeSignUp = ({username, pwd, email}:{username:string, pwd:string, email:string}) => {

  const navigate=useNavigate({from:'/'})
  const [,setSignupCodeStatus]=useState<'idle' | 'in progress'>('idle')
  const queryClient=useQueryClient()
  const resp=queryClient.getQueryData(['Auth']) as ObjectType
  const sub=resp?.cognitoUser.signInUserSession?.idToken.payload.sub as string
  console.log('Sub: ',sub)
  const devUser:DevUser={
    email:email??'',
    userName:username??'',
    sub:sub,
    github_ID:username??'',
    bio:'Your bio/experience'
  }
  
  const {createDev,errors:createAccountErrs,loading}=useCreateDev({
    queryClient, 
    devUser:devUser,
    onSuccess:()=>{
      navigate({to:'/home'})
    }
  })

  
  const { register:confirmationCodeRegister, handleSubmit:confirmSubmit  } = useForm()
  const {mutateAsync:confirmSignUp}=useConfirmSingup()

  if(createAccountErrs){
    toast.error("Unable to login due to an error!",{
      description: `${createAccountErrs.message}`,
      action: {
        label: "Dismiss",
        onClick: () => {},
      },
      style: {
        background: '#dc2626'
      }
    })
  }
  
  const resendConfirmationCodeFunc=async()=>{
    const userName=username
    try{
      await resendConfirmationCode({userName:userName})
      toast.success("Code resent!",{
        description: "Confirmation code resent successfully",
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#a3e635'
        }
      })
    }catch(e){
      toast.error('Try again later', {
        description: `${(e as CustomAxiosError)?.response?.data.error}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      });
    }
    
  } 

  const confirmEmail = async (data:any) => {
    const userName=username;
    const password=pwd
    setSignupCodeStatus('in progress')
    try{
      await confirmSignUp({
          code:data.confirmationCode,
          userName,
          password
        })
      setSignupCodeStatus('idle')
      await createDev()
      //await createAccount()
    }catch(e){
      toast.error("Unable to sign up due to an error!",{
        description: `${(e as CustomAxiosError)?.response?.data.error}`,
        action: {
          label: "Dismiss",
          onClick: () => {},
        },
        style: {
          background: '#dc2626'
        }
      })
      throw ({confirmEmailErr:e})
    }
    

  }

  //if(signupSuccess){
    return (
      <>
        <CardWithNoFooter 
        cardTitle="Confirm e-mail"
        cardDescription="Enter the confirmation code sent to your email"
        classname="bg-white">
          <form onSubmit={confirmSubmit(confirmEmail)} className="flex flex-col space-y-1.5 bg-white">
            <Input  id={'confirmEmail'} type={'text'} {...confirmationCodeRegister('confirmationCode')} name={'confirmationCode'}/>
            <div className="min-h-40">
            {loading && <LinearProgress/> }
            </div>
            <Button type='submit'>Confirm</Button>
          </form>
          <Button
          variant={'link'}
          onClick={resendConfirmationCodeFunc}>
            Resend code
          </Button>
        </CardWithNoFooter> 
        <Toaster/>
      </>
      
    )
  //}


  /* return (
    <AuthCard 
    loading={signUpLoading}
    cardTitle="Create an account"
    cardDescription="Enter your email and password to create an account"
    onSubmit={_triggerSubmit}>
      <form onSubmit={handleSubmit(confirmEmail)}>
        <FormElements
        errors={errors}
        formMembers={[
          {id:"userName",label:"Username",type:"text",register,name:"userName",readOnly:!!username},
          {id:"code",label:"code",type:"code",register,name:"code"},
          {id:"password",label:"Password",type:"password",register,name:"password",readOnly:!!pwd},
          {id:"confirmPassword",label:"Confirm Password",type:"password",register, name:"confirmPassword",readOnly:!!pwd}
      ]}/>
        <input type="submit" value="Submit" style={{ display: 'none' }} ref={submitRef} />
      </form>
    </AuthCard>
  ) */


}

